import { Table } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AiFillDelete } from 'react-icons/ai';
import { AiFillEdit } from "react-icons/ai";
import axios from 'axios';
import BaseUrl from '../variable';
import Spinner from 'react-bootstrap/esm/Spinner';
import { format } from 'date-fns';

interface Pages {
    id: number;
    title: string;
    slug: string;
    user_name: string;
    updatedAt: string;
  }
function Pages() {

    const [data, setData] = useState<Pages[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
  
    useEffect(() => {
      axios.get<Pages[]>(`${BaseUrl}/api/pages/get`)
        .then(response => {          
          const responseData = response.data.map(page => ({
            ...page,
            updatedAt: format(new Date(page.updatedAt), 'PPpp'), // Format the date
          }));
  
          setData(responseData);
          setLoading(false);

        })
        .catch(error => {
          setError('Error fetching data');
          setLoading(false);
        });
    }, []);


    function handleClick(id: number) {
      const confirmed = window.confirm('Are you sure you want to delete this post?');
      if (confirmed) {
        // Make delete request here
        axios.delete(`${BaseUrl}/api/pages/delete/${id}`)
          .then(response => {
              console.log(response);
              toast(response.data.message);
              setTimeout(() => {
                window.location.reload(); 
              }, 4000);
            // handle success
          })
          .catch(error => {
            // handle error
            setError('Not able to delete this post.');
          setLoading(false);
          });
        // alert('Post deleted successfully!');
      }
    }
  
    if (loading) {
      return <div>
        <div className='text-center mt-5 mb-5'>
         <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p className='mt-3'><b>Loading...</b> Please wait.</p>
        </div>
      </div>; // Render loading indicator
  }
    if (error) return <div>{error}</div>;
  
    return (
        <div>
            <ToastContainer/>
            <Link style={{ textDecoration: 'none' }} to="/add-new-page" className="btn btn-outline-dark">
            Add a new page
            </Link>

            <div style={{overflow: "auto"}}>
            <Table size="small" className='mt-4 table-padding'>
            <TableHead>
            <TableRow>
                <TableCell><b>#</b></TableCell>
                <TableCell><b>Title</b></TableCell>
                <TableCell><b>Slug</b></TableCell>
                <TableCell><b>Date</b></TableCell>
                <TableCell  align='right'><b>Actions</b></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {data.map(item => (
                <TableRow key={item.id}>
                <TableCell></TableCell>
                <TableCell>{item.title}</TableCell>
                <TableCell>{item.slug}</TableCell>
                <TableCell>{item.updatedAt}</TableCell>
                <TableCell align='right'>
                    <button className='btn btn-light me-2' onClick={() => handleClick(item.id)}>
                    <AiFillDelete />
                    </button>
                    <a href={`/edit-page/${item.id}`}>
                    <button className='btn btn-light me-2'>
                    <AiFillEdit />
                    </button> 
                    </a>
                </TableCell>
                </TableRow>
            ))
            }
            </TableBody>
            </Table>
            </div>
            </div>
    );
}

export default Pages;