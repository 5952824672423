import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react';

interface BlogsProps {
  pageValue: {
    heading: string;
    subheading: string;
    setcolumns:number;
    // Add other properties as needed
  };
  onHeadingChange: (newTitle: string) => void; // Function to handle heading change in parent
  onSubHeadingChange: (newSubTitle: string) => void; // Function to handle heading change in parent
  onsetColumns: (columns: any) => void;
}

const Blogs: React.FC<BlogsProps> = ({ pageValue, onHeadingChange, onSubHeadingChange, onsetColumns }) => {
  const heading = pageValue.heading || '';
  const subheading = pageValue.subheading || '';
  const columnsinital = pageValue.setcolumns || 4;

  const [title, setTitle] = useState<string>(heading);
  const [subtitle, setSubTitle] = useState<string>(subheading);
  const [columns, setColumns] = useState(columnsinital); // Initial value

  const handleHeadingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = event.target.value;
    setTitle(newTitle);
    onHeadingChange(newTitle); // Send new title to the parent component
  };

  const handleSubHeadingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSubTitle = event.target.value;
    setSubTitle(newSubTitle);
    onSubHeadingChange(newSubTitle); // Send new title to the parent component
  };


  const handleColumnsChange = (event : any ) => {
    setColumns(event.target.value);
    onsetColumns(event.target.value)
  };

  
  return (
    <div>
      <div className='p-3 rounded-4 bg-darklight'>
        <form>
          <p><b>Blogs Layout</b></p>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '100%' }}>
            <TextField
              className='bg-white'
              label="Heading"
              name="heading"
              value={title}
              onChange={handleHeadingChange}
              variant="outlined"
            />
            <TextField
              label="Sub Heading"
              className='bg-white'
              value={subtitle}
              onChange={handleSubHeadingChange}
              name="subheading"
              variant="outlined"
              multiline
              rows={3}
            />

        <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Number of Columns</InputLabel>
              <Select
                className='bg-white'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={columns}
                label="Number of Columns"
                onChange={handleColumnsChange}  >
                <MenuItem value={12}>One</MenuItem>
                <MenuItem value={6}>Two</MenuItem>
                <MenuItem value={4}>Three</MenuItem>
                <MenuItem value={3}>Four</MenuItem>
              </Select>
            </FormControl>


          </Box>
        </form>
      </div>
    </div>
  );
}

export default Blogs;
