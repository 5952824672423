import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';

interface AccordionInput {
  question: string;
  answer: string;
}

interface DynamicAccordionProps {
  acc_title: string;
  accordionValues: AccordionInput[];
  AccordionValueChange: (data: AccordionInput[]) => void;
}

function DynamicAccordion({ acc_title, accordionValues, AccordionValueChange }: DynamicAccordionProps) {
  const [inputs, setInputs] = useState<AccordionInput[]>([]);

  // Use useEffect to update the inputs state whenever accordionValues changes
  useEffect(() => {
    setInputs(accordionValues);
  }, [accordionValues]);

  const handleAddAccordion = () => {
    const newAccordionInput = { question: 'Your Question', answer: 'Your Answer' };
    const updatedInputs = [...inputs, newAccordionInput]; 
    setInputs(updatedInputs);
    AccordionValueChange(updatedInputs);
  };

  const handleRemoveAccordion = (index: number) => {
    const updatedInputs = [...inputs];
    updatedInputs.splice(index, 1);
    setInputs(updatedInputs);
    AccordionValueChange(updatedInputs);
  };

  const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    const updatedInputs = [...inputs];
    updatedInputs[index][name as keyof AccordionInput] = value;
    setInputs(updatedInputs);
    AccordionValueChange(updatedInputs);
  };

  return (
    <div>
      {inputs.map((input, index) => (
        <div key={index}>
          <Button onClick={() => handleRemoveAccordion(index)}>Remove</Button>
          <div className='ms-3'>
            <div className=' mb-3'>
              <TextField
                className='bg-white w-100'
                label="Accordion Question"
                name="question"
                variant="outlined"
                value={input.question}
                onChange={(event) => handleInputChange(index, event)}
              />
            </div>
            <div className='mb-3'>
              <TextField
                className='bg-white w-100'
                label="Accordion Answer"
                name="answer"
                value={input.answer}
                onChange={(event) => handleInputChange(index, event)}
                multiline
                rows={4}
              />
            </div>
          </div>
        </div>
      ))}
      <Button onClick={handleAddAccordion}>Add Accordion</Button>
    </div>
  );
}

export default DynamicAccordion;
