import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
interface ButtonInput {
  bname: string;
  btnclass: string;
  btnlink: string;
}
interface ChildProps {
  sendValuesToParent: (values: ButtonInput[]) => void;
  initialButtonInputs: ButtonInput[]; 

}
function DynamicButtonInputs({ sendValuesToParent, initialButtonInputs }: ChildProps) {
  const childProps: ChildProps = {
    sendValuesToParent: (values: ButtonInput[]) => {}, // Placeholder function
    initialButtonInputs: [] // Add initialButtonInputs property here
  };
  
    const [buttonInputs, setButtonInputs] = useState<ButtonInput[]>(initialButtonInputs || []);
    useEffect(() => {
      if (initialButtonInputs) {
        setButtonInputs(initialButtonInputs);
      }
    }, [initialButtonInputs]);

    // const [buttonInputs, setButtonInputs] = useState<ButtonInput[]>([{ bname: 'Button', btnclass: 'btn', btnlink: '/' }]);
    const handleAddButton = () => {
      const newButtonInput = { bname: 'Button', btnclass: 'btn', btnlink: '/' };
      const updatedInputs = [...buttonInputs, newButtonInput];
      setButtonInputs(updatedInputs);
      sendValuesToParent(updatedInputs);
  };
  
  const handleRemoveButton = (index: number) => {
    const updatedInputs = [...buttonInputs];
    updatedInputs.splice(index, 1);
    setButtonInputs(updatedInputs);
    sendValuesToParent(updatedInputs);
};

  const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    const updatedInputs = [...buttonInputs];
    updatedInputs[index][name as keyof ButtonInput] = value;
    setButtonInputs(updatedInputs);
    sendValuesToParent(updatedInputs);
  };
  return (
    <div>
      {buttonInputs.map((input, index) => (
        <div key={index}>
          <Button onClick={() => handleRemoveButton(index)}>Remove</Button>
          <div className='row'>
            <div className='col-md mb-3'>
              <TextField
                className='bg-white w-100'
                label="Button Name"
                name="bname"
                variant="outlined"
                value={input.bname}
                onChange={(event) => handleInputChange(index, event)}
              />
            </div>
            <div className='col-md mb-3'>

            <TextField
              className='bg-white w-100'
              label="Button Class"
              name="btnclass"
              value={input.btnclass}
              onChange={(event) => handleInputChange(index, event)}
            />
            </div>
            <div className='col-md mb-3'>
            <TextField
              className='bg-white w-100'
              label="Button Link"
              name="btnlink"
              value={input.btnlink}
              onChange={(event) => handleInputChange(index, event)}
            />
            </div>
            </div>
        </div>
      ))}
      <Button onClick={handleAddButton}>Add Button</Button>
    </div>
  );
}

export default DynamicButtonInputs;