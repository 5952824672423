
function BookingList() {
    return (
        <div>
            
            <h4 className='mb-3 fw-800'><b>Booking List</b></h4>
            <table className="table table-pd table-light table-striped mb-4" style={{border : "solid 1px #e3e3e3", }}>
                <tbody>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">
                        Booking Name
                    </th>
                    <th scope="col">
                        Booked Services
                    </th>
                    <th scope="col">
                        Booked On
                    </th>
                    <th scope="col">
                        Booking Time
                    </th>
                    <th scope="col">
                        Staff Name
                    </th>
                </tr>
                <tr>
                    <td>1</td>
                    <td>Jennifer</td>
                    <td>Wash & Blow Dry</td>
                    <td>7Th April 2024 / 10:43 AM</td>
                    <td>9Th April 2024 / 12:30 AM</td>
                    <td>John Doe</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>Christopher</td>
                    <td>Hair Cut & Massage</td>
                    <td>7th Apr 2024, 12:48:24 pm</td>
                    <td>9th Apr 2024, 2:15:00 pm</td>
                    <td>Bob Johnson</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}

export default BookingList;