import { Box, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import htmlEditButton from "quill-html-edit-button";
import ReactQuill, { Quill } from "react-quill";
import BlotFormatter from 'quill-blot-formatter';
import { ImageResize } from 'quill-image-resize-module-ts';
import { ButtonInput } from './Components/types';
import DynamicButtonInputs from './Components/DynamicButtonInputs';
Quill.register("modules/htmlEditButton", htmlEditButton);
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/blotFormatter', BlotFormatter);

const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      [{ 'direction': 'rtl' }],    
      [{ 'align': [] }],
      ['clean']
    ],

    
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
    htmlEditButton: {
      msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
      okText: "Save", // Text to display in the OK button, default: Ok,
      cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
      buttonHTML: "HTML", // Text to display in the toolbar button, default: <>
      buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
      syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
      prependSelector: "div#myelement", // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
      editorModules: {} // The default mod
    },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize']
  }, 
  blotFormatter: {}

  };
  
  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ];

interface BlogsProps {
   onContentChange: (newContent: string) => void; // Function to handle heading change in parent
  pageValue: {
    content: string;
    // Add other properties as needed
  };

  passBtnValue: ButtonInput[]; // Corrected the type
  buttonValueChange: (values: ButtonInput[]) => void;
}

const ContentView: React.FC<BlogsProps> = ({ pageValue, onContentChange, buttonValueChange,passBtnValue }) => {
  const content = pageValue.content || '';
  const [newcontent, setContent] = useState(content);
  

useEffect(()=> {
    const timerId = setTimeout(() => {
        onContentChange(newcontent);
    }, 1500);
    return() => {
      clearTimeout(timerId)
    }
},[newcontent])

    const handleContentChange = (event : string) => {
        setContent(event);
  }
  return (
    <div>
      <div className='p-3 rounded-4 bg-darklight'>
        <form>
          <p><b>Content/ HTML Layout</b></p>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '100%' }}>
            <ReactQuill style={{'height':'300px', background:"white"}}
            modules={modules} 
            id='body'
            theme="snow" 
            value={newcontent} 
            placeholder={'Write something...'}
            onChange={handleContentChange}
            />
            <br />
            <br />
            <br />
            <DynamicButtonInputs sendValuesToParent={buttonValueChange} initialButtonInputs={passBtnValue} />
          </Box>
        </form>
      </div>
    </div>
  );
}

export default ContentView;
