import React from 'react';
import Categories from './Categories';
import ThemeContainer from '../../common/ThemeContainer';
import { Link } from 'react-router-dom';

function index() {
    return (
        <div>
            <ThemeContainer>
                <h4 className='mt-3 mb-4 fw-bold text-start'>Category List</h4>
                <div>
                <Link className='btn btn-outline-dark me-3 mb-3' to='/add-category'>Add New Category</Link>
                </div>
                <Categories />
            </ThemeContainer>
        </div>
    );
}

export default index;