import React, { Component } from 'react';
import ThemeContainer from '../../common/ThemeContainer'
import Profile from './Settings'
class index extends Component {
  render() {
    return (
      <div>
         <ThemeContainer>
            <h4 className='mt-3 mb-4 fw-bold text-start'>Theme Settings</h4>
            <Profile />
          </ThemeContainer>
      </div>
    );
  }
}

export default index;