import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface PageValueState {
    value: [];
}

const initialState: PageValueState = {
    value: [], // Initial state value for the username
};

const pagevalueSlice = createSlice({
    name: 'pagevalue',
    initialState,
    reducers: {
        setPageValue: (state, action: PayloadAction<[]>) => {
             state.value = action.payload; // Mutate the state to update the PageValue value
        },
        resetPageValue: (state) => {
            // Reset userAuth state to initial values by mutating the state object
            state.value = initialState.value;
        },
    },
});

export const { setPageValue, resetPageValue } = pagevalueSlice.actions;
export default pagevalueSlice.reducer;
