import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { AiFillDelete } from 'react-icons/ai';
import { AiFillEdit } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/esm/Spinner';
import BaseUrl from '../variable';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

interface Post {
  id: number;
  title: string;
  post_status: string;
  user_name: string;
  updatedAt: string;
}

function AddBlogs() {
  const [data, setData] = useState<Post[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    axios.get<Post[]>(`${BaseUrl}/api/post/getpost`)
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError('Error fetching data');
        setLoading(false);
      });
  }, []);

  function handleClick(id: number) {
    const confirmed = window.confirm('Are you sure you want to delete this post?');
    if (confirmed) {
      // Make delete request here
      axios.delete(`${BaseUrl}/api/post/delete/${id}`)
        .then(response => {
            console.log(response);
            toast(response.data.message);
            setTimeout(() => {
              window.location.reload(); 
            }, 4000);
          // handle success
        })
        .catch(error => {
          // handle error
          setError('Not able to delete this post.');
        setLoading(false);
        });
      // alert('Post deleted successfully!');
    }
  }

  if (loading) {
    return <div>
      <div className='text-center mt-5 mb-5'>
       <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
      </Spinner>
      <p className='mt-3'><b>Loading...</b> Please wait.</p>
      </div>
    </div>; // Render loading indicator
}
  if (error) return <div>{error}</div>;

  return (
    <div>
        <ToastContainer/>
        <Link style={{ textDecoration: 'none' }} to="/archive-post" className="btn btn-outline-dark me-3">
        View All Post
      </Link>
      <Link style={{ textDecoration: 'none' }} to="/add-new-post" className="btn btn-outline-dark">
        Add a new post
      </Link>
      
      <div style={{overflow: "auto"}}>
      <Table size="small" className='mt-4 table-padding'>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Author</TableCell>
            <TableCell>Date</TableCell>
            <TableCell className='text-center'>Actions</TableCell>
          </TableRow>
          </TableHead>
          <TableBody>
          {data.map(item => (
            <TableRow key={item.id}>
              <TableCell>{item.title}</TableCell>
              <TableCell>{item.post_status}</TableCell>
              <TableCell>{item.user_name}</TableCell>
              <TableCell>{item.updatedAt}</TableCell>
              <TableCell>
                <button className='btn btn-light me-2' onClick={() => handleClick(item.id)}>
                  <AiFillDelete />
                </button>
                
                <Link to={`/edit-post/${item.id}`}>
                <button className='btn btn-light me-2'>
                  <AiFillEdit />
                </button> 
                </Link>
                
                <Link to={`/single-post/${item.id}`}>
                <button className='btn btn-light me-2'>
                <AiFillEye />
                </button> 
                </Link>

              </TableCell>
            </TableRow>
          ))
          }
          </TableBody>
      </Table>
      </div>
    </div>
  );
}

export default AddBlogs;
