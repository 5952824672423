import { useEffect, useRef } from 'react';

// Debouncing function
const useDebounce = (callback: Function, delay: number) => {
    const timerId = useRef<NodeJS.Timeout | null>(null);

    return (...args: any[]) => {
        if (timerId.current) {
            clearTimeout(timerId.current);
        }

        timerId.current = setTimeout(() => {
            callback(...args);
        }, delay);
    };
};

export default useDebounce;