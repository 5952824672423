import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import Spinner from 'react-bootstrap/esm/Spinner';
import { ToastContainer, toast } from 'react-toastify';



function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

function ServiceList() {

    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    useEffect(() => {
      getUser();
    }, []); // Empty dependency array to run the effect only once after initial render
  
    async function getUser() {
      try {
        const response = await axios.get('https://client.mastershairbeaty.co/services');
        setData(response.data.services);
        setLoading(false);
      } catch (error : any) {
        setError(error);
      }
    }

    function handleClick(id: number) {
      const confirmed = window.confirm('Are you sure you want to delete this service?');
      if (confirmed) {

        axios.delete(`https://client.mastershairbeaty.co/deleteService/${id}`)
          .then(response => {
              console.log(response);
              toast.success('Service deleted successfully!');
              setTimeout(() => {
                window.location.reload(); 
              }, 2000);
            // handle success
          })
          .catch(error => {
            // handle error
            setError('Not able to delete this post.');
          setLoading(false);
          });
        
      }
    }

    if (loading) {
        return <div>
          <div className='text-center mt-5 mb-5'>
           <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p className='mt-3'><b>Loading...</b> Please wait.</p>
          </div>
        </div>; // Render loading indicator
    }
    
    if (error) {
        return <div>Error: {error}</div>; // Render error message
    }

  return (
    <React.Fragment>
     
    <br />
      <div style={{overflow: "auto"}}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell><b>Name</b></TableCell>
              <TableCell><b>Description</b></TableCell>
              <TableCell><b>Category</b></TableCell>
              <TableCell><b>Time</b></TableCell>
              <TableCell><b>Price</b></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {data && data.map((item: any) => (
              <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.category.name}</TableCell>
                <TableCell>45 Minutes</TableCell>
                <TableCell align="center">
                  {item.price_ladies && `£${item.price_ladies}`} 
                  {item.price_ladies_oap && `£${item.price_ladies_oap}`}
                  {item.price_children && `£${item.price_children}`}
                  {item.price_men && `£${item.price_men}`}
                  
                  </TableCell>
                <TableCell align="right">
                  <button className='btn btn-light me-2' onClick={() => handleClick(item.id)}>
                    <AiFillDelete />
                  </button></TableCell>
              </TableRow>
        ))}
          </TableBody>
        </Table>
      </div>
    </React.Fragment>
  );
}

export default ServiceList;