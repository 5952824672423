import { combineReducers } from '@reduxjs/toolkit';
import usernameReducer from './usernameSlice';
import tokenSlice from './tokenSlice';
import userRoleSlice from './userRoleSlice';
import userAuthSlice from './userAuthSlice';
import pageValuesSlice from './pageValuesSlice';
const rootReducer = combineReducers({
    username: usernameReducer,
    token: tokenSlice,
    userRole: userRoleSlice,
    userAuth: userAuthSlice,
    pageValues: pageValuesSlice
    // Add other reducers here if needed
});

export default rootReducer;
