import { useState, useEffect } from 'react';
import axios from 'axios';
import BaseUrl from '../variable';

type UserMetaPair = {
    userId: number;
    metaKey: string;
    setMetaValue: (value: any) => void;
  };
  
function GetUserMeta(userMetaPairs: UserMetaPair[]) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
  
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
  
            try {
                const promises = userMetaPairs.map(async ({ userId, metaKey, setMetaValue }) => {
                    const response = await axios.post(`${BaseUrl}/api/user/getmeta/`, {
                        user_id: userId,
                        user_meta: metaKey,
                    });
                    setMetaValue(response.data[0]?.meta_value);
                });
  
                await Promise.all(promises);
                setLoading(false); // Set loading to false after data is fetched
            } catch (error) {
                setError('Error fetching data');
                setLoading(false); // Set loading to false in case of error
            }
        };
  
        fetchData();
    }, []); // Removed userMetaPairs from dependencies to fetch data only once
  
    return { loading, error };
  }

export default GetUserMeta;
