import  { useEffect, useState } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { ToastContainer, toast } from 'react-toastify';
import BaseUrl from '../variable';
import { Link } from 'react-router-dom';


interface SubUser{
    id: number;
    title: string;
    createdAt: string;
    username: string;
    users: any[]; 
}
function StaffMembers() {
    const [StaffMember, setStaffMember] = useState<SubUser[]>([]);
    const [loading, setLoading] = useState<boolean>(true); // State for loading indicator
    const [error, setError] = useState<string>(''); // State for error message
    
    useEffect(() => {
        const getStaffMember = async () => {
          try {
            const response = await axios.get(`${BaseUrl}/api/staff/get`);
            setStaffMember(response.data);
            setLoading(false);
          } catch (error: any) {
            toast.error('Error fetching users:', error);
            setError('Error fetching users');
            setLoading(false);
          }
        };
    
        getStaffMember();
      }, []);

    if (loading) {
        return <div>
          <div className='text-center mt-5 mb-5'>
           <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p className='mt-3'><b>Loading...</b> Please wait.</p>
          </div>
        </div>; // Render loading indicator
    }

    if (error) {
        return <div>Error: {error}</div>; // Render error message
    }

    function handleClick(id: number) {
        const confirmed = window.confirm(`Are you sure you want to delete this Staff Member?`);
        if (confirmed) {
            axios.delete(`${BaseUrl}/api/staff/delete/${id}`)
                .then(response => {
                    console.log(response);
                    toast.success('Staff deleted successfully!');
                    setTimeout(() => {
                    window.location.reload(); 
                    }, 2000);
                // handle success
                })
                .catch(error => {
                // handle error
                toast.error('Not able to delete this post.');
                setLoading(false);
                });
        }
    }

    return (
        <div>
            <ToastContainer></ToastContainer>

            <TableContainer>
                <Table size="small" aria-label="customized table" className='table-padding'>
                    <TableHead>
                    <TableRow>
                        <TableCell width={"30px"}><b>#</b></TableCell>
                        <TableCell width={"30px"}><b>ID</b></TableCell>
                        <TableCell><b>Name</b></TableCell>
                        <TableCell><b>Created On</b></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                       {StaffMember && StaffMember.length > 0 ?
                        StaffMember.map((member, index) => (
                            <TableRow key={index + 1}>
                            <TableCell>
                            </TableCell>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{member.title}</TableCell>
                            <TableCell>{member.createdAt}</TableCell>
                            <TableCell align="right">
                                <Link to={`/edit-staff/${member.id}`}>
                                <button className='btn btn-light me-2'>
                                <AiFillEdit />
                                </button> 
                                </Link>
                                <button className='btn btn-light me-2' onClick={() => handleClick(member.id)}>
                                    <AiFillDelete />
                                </button>
                            </TableCell>
                            </TableRow>
                        )) : 
                        
                        <TableRow>
                        <TableCell colSpan={6} align="center">
                            No records found
                        </TableCell>
                    </TableRow>}
                 </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default StaffMembers;
