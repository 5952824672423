import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ThemeContainer from '../../common/ThemeContainer';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function AddCategory() {

    const navigate = useNavigate();
    
    function handleSubmit(e: any) {
      e.preventDefault();
      const name = e.target.addcategory.value;
    
      fetch('https://client.mastershairbeaty.co/categories', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: name }), // Corrected the body format
      })
        .then((response) => {
          if (response.ok) {
            toast.success('Service inserted successfully'); // Use toast.success for success message
            setTimeout(() => {
              navigate('/categories');
            }, 3000); // Redirect after 3 seconds
          } else {
            toast.error('Failed to insert service'); // Use toast.error for error message
          }
        })
        .catch((error) => console.error('Error inserting service:', error));
    }
    
    return (
        <div>
            <ToastContainer></ToastContainer>
            <ThemeContainer>
            <h4 className='mt-3 mb-4 fw-bold text-start'>Add New Category</h4>
            <form onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '400px' }}>
          
                <TextField
                label="Add Category"
                name="addcategory"
                variant="outlined"
            />
            <Button style={{width : "150px"}} type="submit" variant="contained" color="primary">
                    Submit
                    </Button>
                </Box>
                </form>
            </ThemeContainer>
        </div>
    );
}

export default AddCategory;