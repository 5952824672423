import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Appbar from "./Appbar"
import Dashview from "./DashView"
import AuthUser from '../Dashbaord/Auth/AuthUser';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { resetUserAuth } from '../Redux/userAuthSlice';
import { resetUsername } from '../Redux/usernameSlice';
import { resetToken } from '../Redux/tokenSlice';
import { resetUserRole } from '../Redux/userRoleSlice';
import { useDispatch } from 'react-redux';
import BaseUrl from '../variable';
const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window?: () => Window;
}

function ResponsiveDrawer(props: Props) {
  AuthUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Logout = async () => {
    try {
        await axios.delete(`${BaseUrl}/logout`, {
          withCredentials: true,
          headers: {
              'Content-Type': 'application/json'
          }});
          dispatch(resetUserAuth());
          dispatch(resetUsername());
          dispatch(resetToken());
          dispatch(resetUserRole());
          navigate("/login");
    } catch (error) {
        console.log(error);
    }
  
}
  const drawer = (
    <div>
      <Toolbar />
      <List>
          <ListItem disablePadding>
            <ListItemButton className='pt-4 pb-4'>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={"Dashboard"} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton className='pt-4 pb-4'>
              <ListItemIcon>
                <StarBorderIcon />
              </ListItemIcon>
              <ListItemText primary={"Bookings"} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton className='pt-4 pb-4'>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={"Profile Settings"} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton className='pt-4 pb-4'>
              <ListItemIcon>
                <AddCircleOutlineIcon />
              </ListItemIcon>
              <ListItemText primary={"Create Booking"} />
            </ListItemButton>
          </ListItem>
          <Link to="" style={{textDecoration: "none", color: "inherit"}} onClick={Logout}>
            <ListItem disablePadding>
              <ListItemButton className='pt-4 pb-4'>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItemButton>
            </ListItem>
          </Link>
      </List>
    
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
     <Appbar></Appbar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth,color: "grey", backgroundColor: "#f8f9fa", borderRight: "solid 1px #dadada",boxShadow : "0 1rem 3rem rgba(0, 0, 0, 0.175);" },
            '& .css-cveggr-MuiListItemIcon-root' : {minWidth: "40px",},
            '& .css-10hburv-MuiTypography-root' : {fontWeight: "500"}
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    <Dashview></Dashview>
    </Box>
  );
}

export default ResponsiveDrawer