import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserAuthState {
    value: boolean;
}

const initialState: UserAuthState = {
    value: false, // Initial state value for the user authentication status
};

const userAuthSlice = createSlice({
    name: 'userAuth',
    initialState,
    reducers: {
        setUserAuth: (state, action: PayloadAction<boolean>) => {
             state.value = action.payload; // Mutate the state to update the user authentication status
        },
        resetUserAuth: (state) => {
            // Reset userAuth state to initial values by mutating the state object
            state.value = initialState.value;
        },
    },
});

export const { setUserAuth, resetUserAuth } = userAuthSlice.actions;
export default userAuthSlice.reducer;
