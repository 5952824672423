import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import HomeIcon from '@mui/icons-material/Home';
import BlogIcon from '@mui/icons-material/MessageRounded';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CategoryIcon from '@mui/icons-material/Category';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import LayersIcon from '@mui/icons-material/Layers';
import axios from 'axios';
import {
  Link, useNavigate
} from "react-router-dom";
import { resetUserAuth } from '../Redux/userAuthSlice';
import { resetUsername } from '../Redux/usernameSlice';
import { resetToken } from '../Redux/tokenSlice';
import { resetUserRole } from '../Redux/userRoleSlice';
import { useDispatch } from 'react-redux';
import BaseUrl from '../variable';

const LogoutButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Logout = async () => {
    try {
      await axios.delete(`${BaseUrl}/logout`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      dispatch(resetUserAuth());
      dispatch(resetUsername());
      dispatch(resetToken());
      dispatch(resetUserRole());
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Link style={{ textDecoration: 'none' }} to="" onClick={Logout}>
      <ListItemButton>
        <ListItemIcon>
          <LogoutIcon sx={{
            width: "25",
            color: '#ffffff8a',
            fontWeight: '800',
          }} />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </Link>
  );
};

export const mainListItems = (
  <React.Fragment>
    <Link style={{ textDecoration: 'none' }} to='/dashboard'>
      <ListItemButton sx={{
        background: '#000000',
        borderRadius: '15px',
        color: 'white',
        fontWeight: '800',
      }}>
        <ListItemIcon>
          <HomeIcon sx={{
            width: "25",
            color: 'white',
            fontWeight: '800',
          }} />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
    </Link>
    <Link style={{ textDecoration: 'none' }} to='/booking'>
      <ListItemButton>
        <ListItemIcon>
          <ConfirmationNumberIcon sx={{
            width: "25",
            color: '#ffffff8a',
            fontWeight: '800',
          }} />
        </ListItemIcon >
        <ListItemText primary="Bookings" />
      </ListItemButton>
    </Link>
    <Link style={{ textDecoration: 'none' }} to='/pages'>
      <ListItemButton>
        <ListItemIcon>
          <LayersIcon sx={{
            width: "25",
            color: '#ffffff8a',
            fontWeight: '800',
          }} />
        </ListItemIcon >
        <ListItemText primary="Pages" />
      </ListItemButton>
    </Link>
    <Link style={{ textDecoration: 'none' }} to='/categories'>
      <ListItemButton>
        <ListItemIcon>
          <CategoryIcon sx={{
            width: "25",
            color: '#ffffff8a',
            fontWeight: '800',
          }} />
        </ListItemIcon>
        <ListItemText primary="Categories" />
      </ListItemButton>
    </Link>

    <Link style={{ textDecoration: 'none' }} to='/services'>
      <ListItemButton>
        <ListItemIcon>
          <ShoppingCartIcon sx={{
            width: "25",
            color: '#ffffff8a',
            fontWeight: '800',
          }} />
        </ListItemIcon>
        <ListItemText primary="Services" />
      </ListItemButton>
    </Link>


    <Link style={{ textDecoration: 'none' }} to='/blogs'>
      <ListItemButton>
        <ListItemIcon>
          <BlogIcon sx={{
            width: "25",
            color: '#ffffff8a',
            fontWeight: '800',
          }} />
        </ListItemIcon>
        <ListItemText primary="Blogs" />
      </ListItemButton>
    </Link>
    <Link style={{ textDecoration: 'none' }} to='/users'>
      <ListItemButton>
        <ListItemIcon>
          <PeopleIcon sx={{
            width: "25",
            color: '#ffffff8a',
            fontWeight: '800',
          }} />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItemButton>
    </Link>

    <Link style={{ textDecoration: 'none' }} to='/staff'>
      <ListItemButton>
        <ListItemIcon>
          <SupervisedUserCircleIcon sx={{
            width: "25",
            color: '#ffffff8a',
            fontWeight: '800',
          }} />
        </ListItemIcon>
        <ListItemText primary="Staff" />
      </ListItemButton>
    </Link>

    <Link style={{ textDecoration: 'none' }} to='/profile'>
      <ListItemButton>
        <ListItemIcon>
          <PersonPinIcon sx={{
            width: "25",
            color: '#ffffff8a',
            fontWeight: '800',
          }} />
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </ListItemButton>
    </Link>
    <Link style={{ textDecoration: 'none' }} to='/settings'>
      <ListItemButton>
        <ListItemIcon>
          <SettingsIcon sx={{
            width: "25",
            color: '#ffffff8a',
            fontWeight: '800',
          }} />
        </ListItemIcon>
        <ListItemText primary="Setting" />
      </ListItemButton>
    </Link>
    <LogoutButton />
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton>
  </React.Fragment>
);
