import React, { useEffect, useState } from 'react';
import ThemeContainer from '../../common/ThemeContainer';
import axios from 'axios';
import Table from '@mui/material/Table';
import Spinner from 'react-bootstrap/esm/Spinner';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { AiFillDelete } from 'react-icons/ai';
import { ToastContainer, toast } from 'react-toastify';

interface SubUser {
  id: number;
  name: string;
}
 
function Categories() {
  
    const [categories, setCategories] = useState<SubUser[]>([]);
    const [loading, setLoading] = useState<boolean>(true); // State for loading indicator
    const [error, setError] = useState<string>(''); // State for error message
    console.log(categories);
    useEffect(() => {
        const getCategories = async () => {
          try {
            const response = await axios.get('https://client.mastershairbeaty.co/categories');
            setCategories(response.data.categories);
            setLoading(false);
          } catch (error) {
            console.error('Error fetching users:', error);
            setError('Error fetching users');
            setLoading(false);
          }
        };
    
        getCategories();
      }, []);

      if (loading) {
        return <div>
          <div className='text-center mt-5 mb-5'>
           <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p className='mt-3'><b>Loading...</b> Please wait.</p>
          </div>
        </div>; // Render loading indicator
    }

    if (error) {
        return <div>Error: {error}</div>; // Render error message
    }


  function handledeleteClick(id: number, name: any) {
    
    const confirmed = window.confirm('Are you sure you want to delete this category?');
    if (confirmed) {

      axios.delete(`https://client.mastershairbeaty.co/deleteCategory/${id}`)
        .then(response => {
            console.log(response);
            toast.success(response.data.message);
            setTimeout(() => {
              window.location.reload(); 
            }, 2000);
          // handle success
        })
        .catch(error => {
          // handle error
          setError('Not able to delete this category.');
        setLoading(false);
        });
        toast.success("Category " + name + " has been deleted" )
    }
  }
    return (
        <div>
          <ToastContainer></ToastContainer>
            <Table size="small" className='table-padding'>
              <TableHead>
                <TableRow>
                  <TableCell width={"30px"}><b>#</b></TableCell>
                  <TableCell width={"30px"}><b>ID</b></TableCell>
                  <TableCell><b>Category</b></TableCell>
                  <TableCell><b></b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map((user, index) => (
                    <TableRow key={user.id}>
                    <TableCell></TableCell>
                    <TableCell>{user.id}</TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell align="right">
                    <button className='btn btn-light me-2' onClick={() => handledeleteClick(user.id, user.name)}>
                      <AiFillDelete />
                    </button>
                    </TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
        </div>
    );
}

export default Categories;