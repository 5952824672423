import { useState, useEffect } from 'react';
import axios from 'axios';
import BaseUrl from '../variable';
function TestValue() {
    const [ instagram , setInstagram ] = useState<string | null>(null);
    const [ website_url , setWebsiteUrl ] = useState<string | null>(null);
    const [ siteTitle , setTitle ] = useState<string | null>(null);
    const [ facebook , setFacebook ] = useState<string | null>(null);
    const [ twitter , setTwitter ] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    // console.log("instagram : " + instagram, "website link : " + website_url, "siteTitle :" + siteTitle, "facebook : "+ facebook, "twitter : " + twitter )
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            
            try {
                const response = await axios.post(`${BaseUrl}/api/getoptions/`, {
                    option_name: ["website_url", "sitename", "instagram", "facebook", "twitter", "mobile_number", "location", "email_us"],
                });
                // console.log(response.data);
                setTitle(response.data[0].option_value);
                setWebsiteUrl(response.data[1].option_value);
                setInstagram(response.data[2].option_value);
                setFacebook(response.data[3].option_value);
                setTwitter(response.data[4].option_value);
                setLoading(false); // Set loading to false after data is fetched
            } catch (error) {
                setError('Error fetching data');
                setLoading(false); // Set loading to false in case of error
            }
        };
  
        fetchData();
    }, []); // Removed userMetaPairs from dependencies to fetch data only once
    return { loading, error };
}
export default TestValue;