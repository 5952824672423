import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BaseUrl from '../../variable';

function ViewPage() {
    const { slug } = useParams<{ slug: string }>();
    const [pageData, setPageData] = useState<any>(null); // State to store page data

    useEffect(() => {
        const getPageBySlug = async (slug: string | undefined) => {
            try {
                // Make a GET request to your backend API to fetch page data based on the slug
                const response = await fetch(`${BaseUrl}/api/pages/${slug}`); // Adjust the API endpoint as per your backend route
                console.log(response)
                if (!response.ok) {
                    throw new Error('Failed to fetch page data');
                }
                
                // Parse the JSON response
                const pageData = await response.json();
                return pageData;
            } catch (error : any) {
                console.error('Error fetching page data:', error.message);
                // You can handle errors here, e.g., show an error message to the user
                return null; // Or return a default page data object
            }
        };

        // Call getPageBySlug function when the component mounts
        getPageBySlug(slug).then((data) => {
            setPageData(data);
        });
    }, [slug]); // Run the effect whenever the slug changes

    return (
        <div>
            {pageData ? (
                <div>
                    <h1>{pageData.title}</h1>
                    <p>{pageData.body}</p>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}

export default ViewPage;
