import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TableContainer, Paper,  TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
interface Slot {
  id: string;
  staff_member: string;
  service_name: string;
  service_description: string;
  service_price: number;
  service_price_ladies: number;
  service_price_ladies_oap: number | null;
  service_price_children: number | null;
  slot_datetimes: string[];
}

interface UserBooking {
  user_username: string;
  booking_time: string;
  bookedslots: Record<string, Slot[]>;
}

const DashboardBooking: React.FC = () => {
  const [bookingDetails, setBookingDetails] = useState<UserBooking[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  useEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        const response = await axios.get<{ bookingDetails: UserBooking[] }>('https://client.mastershairbeaty.co/booking-details');
        console.log(response.data.bookingDetails);
        setBookingDetails(response.data.bookingDetails);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching booking details:', error);
      }
    };

    fetchBookingDetails();
  }, []);

  if (loading) {
    return <div>
      <div className='text-center mt-5 mb-5'>
       <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
      </Spinner>
      <p className='mt-3'><b>Loading...</b> Please wait.</p>
      </div>
    </div>; // Render loading indicator
}

if (error) {
    return <div>Error: {error}</div>; // Render error message
}

  return (
    <div>
      <TableContainer>
      <Table>
        <TableBody>
          {bookingDetails.map((user , i) => (
            <>
              {Object.entries(user.bookedslots).map(([bookingId, bookingSlots]) => (
                <>
                   <div className='pb-1 pt-3 text-capitalize'><b>Full Name:</b>{user.user_username}</div>
                  {bookingSlots.map((slot) => (
                    <React.Fragment key={`${user.user_username}-${bookingId}-${slot.id}`}>
                      
                      <Table striped bordered hover className='mt-2'>
                        <TableHead>
                          <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Staff Name</TableCell>
                          <TableCell>Service Name</TableCell>
                          <TableCell>Service Description</TableCell>
                          <TableCell>Booking Date/Time</TableCell>
                          <TableCell>Slot Time</TableCell>
                          </TableRow>
                          </TableHead>
                          <TableBody>
                          <TableRow>
                              <TableCell>{slot.id}</TableCell>
                              <TableCell>{slot.staff_member}</TableCell>
                              <TableCell>{slot.service_name}</TableCell>
                              <TableCell>{slot.service_description}</TableCell>
                              <TableCell>{user.booking_time}</TableCell>
                              <TableCell>{slot.slot_datetimes}</TableCell>
                          </TableRow>
                        </TableBody>
                        </Table>
                    </React.Fragment>
                  ))}
                </>
              ))}
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
};

export default DashboardBooking;
