import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer,FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER, } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import rootReducer from './rootReducer';

// Configure Redux Persist options
const persistConfig = {
  key: 'root',
  storage,
  // Optionally, you can whitelist or blacklist specific reducers
  // whitelist: ['reducer1', 'reducer2'],
  // blacklist: ['reducerToIgnore'],
};

// Create the persisted reducer with Redux Persist configuration
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store with the persisted reducer
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create the persistor object for persisting the Redux store
export const persistor = persistStore(store);

// Export the store and infer RootState and AppDispatch types
export default store;

// Infer the `RootState` type from the store's state
export type RootState = ReturnType<typeof store.getState>;

// Define AppDispatch type as the type of store.dispatch
export type AppDispatch = typeof store.dispatch;
