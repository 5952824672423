import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ThemeContainer from '../../common/ThemeContainer';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BaseUrl from '../variable';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'; // Import delete icon

interface Post {
  id: number;
  title: string;
  body: string;
  author: string;
  createdAt: string;
  post_status: string;
  featured_img: string;
}

const modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' },
    { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'video'],
    ['clean']
  ],
  clipboard: {
    matchVisual: false,
  }
}

const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
]

  const EditPostPage: React.FC = () => {

    const [file, setFile] = useState<File | null>(null);
    const [Path, setImagePath] = useState<string | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [post_status, setStatus] = useState('');
    const [setFeaturedPath, setPath] = useState('');
    const { id } = useParams<{ id: string }>();

    
    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = e.target.files && e.target.files[0];
      console.log(selectedFile)
      if (selectedFile) {
        setFile(selectedFile);
        setPreviewUrl(URL.createObjectURL(selectedFile));
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        };
        const formData = new FormData();
        formData.append("image", selectedFile);
        const response = await axios.post(`${BaseUrl}/api/upload`, formData , config);
        if (response.status === 200) {
          setImagePath(response.data.imagePath)
          const imgpath = response.data.imagePath;
          console.log("Image uploaded successfully!");
          handleImageUpdatePost(imgpath);
        } else {
          console.log("Error uploading image!");
        }
      }
    };
    
  useEffect(() => {
    axios.get<Post>(`${BaseUrl}/api/post/single/${id}`)
      .then(response => {
        const postData = response.data;
        setTitle(postData.title);
        setBody(postData.body);
        setStatus(postData.post_status);
        setPath(postData.featured_img);
      })
      .catch(error => {
        console.error('Error fetching post:', error);
      });
  }, [id]);

  const handleImageUpdatePost = (imgpath:string) => {
    const data = {
        id : id,
        featured_img : imgpath
    }
    
    axios.post(`${BaseUrl}/api/post/updatepost`, data)
    .then(response => {
      if (response.status === 200) {
        toast(response.data.msg);
      } }
      ).catch((err) => console.log(err));
      console.log(data)
}

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  }
  
  const handleBodyChange = (value: string) => {
    setBody(value);
  }
  const handleStatusChange = (e : any) => {
    setStatus(e.target.value);
  }

  const handleUpdatePost = () => {
    const data = {
        id : id,
        title : title,
        body : body,
        status : post_status,
        featured_img : Path

    }
    
    axios.post(`${BaseUrl}/api/post/updatepost`, data)
    .then(response => {
      if (response.status === 200) {
        toast(response.data.msg);
      } }
      ).catch((err) => console.log(err));
      console.log(data)
}

const handleRemoveImg = async (passImageValue: string) => {
  try {
    const filename = passImageValue.substring(passImageValue.lastIndexOf('/') + 1);
    const response = await axios.delete(`${BaseUrl}/api/remove/img/${filename}`);
    if (response.status === 200) {
      setImagePath(''); // Clear imagePath in case of successful deletion
      setPreviewUrl(null);
      toast.success("Image deleted successfully!");
    } else {
      toast.error("Error deleting image!");
    }
  } catch (error) {
    console.error("Error:", error);
    toast.error("Error deleting image!");
  }
};

  return (
    <div>
    <ToastContainer />
      <ThemeContainer>
        <Container>
          <Row>
            <Col lg={8}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" required name='title' value={title} onChange={handleTitleChange} placeholder="Add title" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Content</Form.Label>
                <ReactQuill style={{'height':'380px'}}
                  modules={modules}
                  id='body'
                  theme="snow"
                  value={body}
                  formats={formats}
                  onChange={handleBodyChange}
                />
              </Form.Group>
              <br />
              <br />
              <br />
            </Col>
            <Col lg={4}>
            <button className='btn btn-success mb-3 me-3' onClick={handleUpdatePost}>Update</button>
            <Link  to={`/single-post/${id}`} className='btn btn-dark mb-3'>View Post</Link>
            <br />
            <label htmlFor="">Status</label>
                <Form.Select aria-label="post_status" onChange={handleStatusChange} name='post_status' value={post_status} className='mb-3'>
                    <option value="publish">Published</option>
                    <option value="pending">Pending Review</option>
                    <option value="draft">Draft</option>
                </Form.Select>

                {setFeaturedPath && <div style={{ position: "relative",maxWidth:"350px"}}>
                  <img src={`${BaseUrl}${setFeaturedPath}`} className='rounded-3'  alt=""  width={'100%'}  style={{}} />
                    <IconButton className="bg-danger" onClick={() => handleRemoveImg(setFeaturedPath)} style={{ position: "absolute", top: "5px", right: "5px" }}>
                        <DeleteIcon className="text-white" />
                    </IconButton>
                    <hr />
                </div>}
                {!setFeaturedPath &&  <input
                  accept="image/png, image/gif, image/jpeg"
                  type="file"
                  name="featured_img"
                  onChange={handleFileChange}
                />}
             
            </Col>
          </Row>
        </Container>
      </ThemeContainer>
    </div>
  );
};

export default EditPostPage;
