import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface UserroleState {
    value: string;
}

const initialState: UserroleState = {
    value: '', // Initial state value for the username
};

const userroleSlice = createSlice({
    name: 'userrole',
    initialState,
    reducers: {
        setUserRole: (state, action: PayloadAction<string>) => {
             state.value = action.payload; // Mutate the state to update the username value
        },
        resetUserRole: (state) => {
            // Reset userAuth state to initial values by mutating the state object
            state.value = initialState.value;
        },
    },
});

export const { setUserRole, resetUserRole } = userroleSlice.actions;
export default userroleSlice.reducer;
