import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface TokenState {
    value: string;
}

const initialState: TokenState = {
    value: '', // Initial state value for the username
};

const tokenSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
             state.value = action.payload; // Mutate the state to update the username value
        },
        resetToken: (state) => {
            // Reset userAuth state to initial values by mutating the state object
            state.value = initialState.value;
        },
    },
});

export const { setToken, resetToken } = tokenSlice.actions;
export default tokenSlice.reducer;
