import { Box, TextField } from '@mui/material';
import React, { useState } from 'react';
import DynamicButtonInputs from './Components/DynamicButtonInputs';
import { ButtonInput } from './Components/types';

interface ServicesProps {
  pageValue: {
    heading: string;
    subheading: string;
    // Add other properties as needed
  };
  passBtnValue: ButtonInput[]; // Corrected the type
  onHeadingChange: (newTitle: string) => void; // Function to handle heading change in parent
  onSubHeadingChange: (newSubTitle: string) => void; // Function to handle heading change in parent
  buttonValueChange: (values: ButtonInput[]) => void;
}

const Services: React.FC<ServicesProps> = ({ pageValue, onHeadingChange, onSubHeadingChange, buttonValueChange,passBtnValue }) => {
  const heading = pageValue.heading || '';
  const subheading = pageValue.subheading || '';

  const [title, setTitle] = useState<string>(heading);
  const [subtitle, setSubTitle] = useState<string>(subheading);

  const handleHeadingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = event.target.value;
    setTitle(newTitle);
    onHeadingChange(newTitle); // Send new title to the parent component
  };

  const handleSubHeadingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSubTitle = event.target.value;
    setSubTitle(newSubTitle);
    onSubHeadingChange(newSubTitle); // Send new title to the parent component
  };

  
  return (
    <div>
      <div className='p-3 rounded-4 mb-3 bg-darklight'>
        <form>
          <p><b>Services List</b></p>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '100%' }}>
            <TextField
              className='bg-white'
              label="Heading"
              name="heading"
              value={title}
              onChange={handleHeadingChange}
              variant="outlined"
            />
            <TextField
              label="Sub Heading"
              className='bg-white'
              value={subtitle}
              onChange={handleSubHeadingChange}
              name="subheading"
              variant="outlined"
              multiline
              rows={3}
            />
            <DynamicButtonInputs sendValuesToParent={buttonValueChange} initialButtonInputs={passBtnValue} />
          </Box>
        </form>
      </div>
    </div>
  );
}

export default Services;
