import  { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from "react-redux";
import { RootState } from '../Redux/store';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import BaseUrl from '../variable';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { AiFillDelete } from 'react-icons/ai';
import { ToastContainer, toast } from 'react-toastify';

interface User {
    id: number;
    name: string;
    email: string;
}
interface SubUser{
    id: number;
    email: string;
    role: string;
    username: string;
    users: any[]; 
}


function User() {
    const token = useSelector((state: RootState) => state.token.value);
    const [ users, setUsers ] = useState<User[]>([]); // Specify the User type for users
    
    // console.log(users);
    const [loading, setLoading] = useState<boolean>(true); // State for loading indicator
    const [error, setError] = useState<string>(''); // State for error message
    
    useEffect(() => {
        const getUsers = async () => {
            try {
                const response = await axios.get<User[]>(`${BaseUrl}/users`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                });
                // console.log(response.data); // Assuming response.data is an array of users
                setUsers(response.data); // Set the fetched users to the state
                setLoading(false); // Set loading to false after data is fetched
            } catch (error) {
                console.error('Error fetching users:', error);
                setError('Error fetching users'); // Set error message
                setLoading(false); // Set loading to false on error
            }
        };

        getUsers();
    }, [token]); // Include token in the dependency array


    const [subUsers, setSubUsers] = useState<SubUser[]>([]);

    useEffect(() => {
        const getSubUsers = async () => {
          try {
            const response = await axios.get('https://client.mastershairbeaty.co/getusers');
            console.log(response.data);

            setSubUsers(response.data.users);
            setLoading(false);
          } catch (error) {
            console.error('Error fetching users:', error);
            setError('Error fetching users');
            setLoading(false);
          }
        };
    
        getSubUsers();
      }, []);

    if (loading) {
        return <div>
          <div className='text-center mt-5 mb-5'>
           <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p className='mt-3'><b>Loading...</b> Please wait.</p>
          </div>
        </div>; // Render loading indicator
    }

    if (error) {
        return <div>Error: {error}</div>; // Render error message
    }

    function handleClick(id: number) {
        const confirmed = window.confirm(`Are you sure you want to delete this user?`);
        if (confirmed) {
            const confirmeduser = window.confirm('User data will be deleted.');
            if (confirmeduser) {
            axios.delete(`https://client.mastershairbeaty.co/deleteUser/${id}`)
                .then(response => {
                    console.log(response);
                    toast.success('Service deleted successfully!');
                    setTimeout(() => {
                    window.location.reload(); 
                    }, 2000);
                // handle success
                })
                .catch(error => {
                // handle error
                setError('Not able to delete this post.');
                setLoading(false);
                });
            
            }
        }
    }

    return (
        <div>
            <ToastContainer></ToastContainer>
            <TableContainer>
                <Table size="small" className='table-padding'>
                    <TableHead>
                    <TableRow>
                        <TableCell width={"30px"}><b>#</b></TableCell>
                        <TableCell width={"30px"}><b>ID</b></TableCell>
                        <TableCell><b>Name</b></TableCell>
                        <TableCell><b>Email</b></TableCell>
                        <TableCell><b>Role</b></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {users.map((user, index) => (
                        <TableRow key={user.id}>
                        <TableCell></TableCell>
                        <TableCell>{user.id}</TableCell>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.id === 1 ? "Administrator" : "Subscriber"}</TableCell>
                        <TableCell align="right">
                            <button className='btn btn-light me-2' 
                            //onClick={() => handleClick(user.id)}
                            >
                            <AiFillDelete />
                            </button>
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TableContainer>
                <Table size="small" aria-label="customized table" className='table-padding'>
                    <TableHead>
                    <TableRow>
                        <TableCell width={"30px"}><b>#</b></TableCell>
                        <TableCell width={"30px"}><b>ID</b></TableCell>
                        <TableCell><b>Name</b></TableCell>
                        <TableCell><b>Email</b></TableCell>
                        <TableCell><b>Role</b></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                {subUsers.map((user, index) => (
                    <TableRow key={index + 1}>
                    <TableCell>
                    </TableCell>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell align="right">
                        <button className='btn btn-light me-2' onClick={() => handleClick(user.id)}>
                            <AiFillDelete />
                        </button>
                    </TableCell>
                    </TableRow>
                ))}
                 </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default User;
