import ThemeContainer from '../../common/ThemeContainer';
import Pages from './Pages';
function index() {
  return (
    <>
    <ThemeContainer>
      <Pages></Pages>
    </ThemeContainer>
    </>
  );
}

export default index;