import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import BaseUrl from '../variable';
interface Post {
    id: number;
    title: string;
    post_status: string;
    body: string;
    user_name: string;
    updatedAt: string;
  }  

function ArchivePost() {
  const [data, setData] = useState<Post[]>([]);
  console.log(data);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    axios.get<Post[]>(`${BaseUrl}/api/post/getpost`)
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError('Error fetching data');
        setLoading(false);
      });
  }, []);
    return (
        <div>
          <Container>
            <Row>
              {data.map(item => (
                <Col key={item.id} lg={4}>
                <div>
                <Image width={'100%'} rounded src="https://appify.vercel.app/static/media/gift.d5993ae6a547084c8af1.jpg" height="206.25" alt="MASTERS OFFERS AND EVENTS"/>
                  <h5>{item.title}</h5>
                  <div dangerouslySetInnerHTML={{ __html: item.body.substring(0, 110) }}></div>
                  {/* <div>{item.post_status}</div>
                  <div>{item.user_name}</div>
                  <div>{item.updatedAt}</div> */}
                </div>
                <Link to={`/single-post/${item.id}`}>
                  Read More
                </Link>
                </Col>
            )) }
            </Row>
          </Container>
        </div>
        
    );
}

export default ArchivePost;