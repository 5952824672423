import './App.css';
import { createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashbaord';
import Booking from './pages/Bookings';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import Users from './pages/User';
import Pages from './pages/Pages';
import Register from './pages/Register';
import ErrorPage from './pages/ErrorPage';
import Blogs from './pages/Blogs';
import AddNewPost from './pages/Blogs/AddBlogs';
import ArchivePost from './pages/ShowPost/archivePost';
import SinglePost from './pages/ShowPost/singlePost';
import EditBlog from './pages/Blogs/EditBlog';
import UserDashboard from './pages/UserDashboard'
import { useSelector } from 'react-redux';
import { RootState } from './pages/Redux/store';
import Services from "./pages/Services";
import AddService from "./pages/Services/AddServices";
import Categories from './pages/Category';
import AddCategory from './pages/Category/AddCategory';
import Staff from "./pages/Staff"
import AddStaff from "./pages/Staff/AddStaff"
import EditStaff from "./pages/Staff/EditStaff"
import EditPage from './pages/Pages/EditPage';
import AddPage from './pages/Pages/AddPage';
import ViewPage from './pages/Pages/ViewPage/ViewPage';
const theme = createTheme({
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
  },
});


function App() {
   const userRole = useSelector((state: RootState) => state.userRole.value);
   const userAuth = useSelector((state: RootState) => state.userAuth.value);
  //  console.log(userRole,userAuth)
   
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route
            path="/login"
            element={userAuth ? <Navigate to="/dashboard" replace /> : <Login />}
          />
           {/* <Route
            path="/register"
            element={userAuth ? <Navigate to="/dashboard" replace /> : <Register />}
          /> */}
          <Route path="/" element={userAuth ? <Dashboard /> : <Navigate to="/login" replace />} />
          <Route path="/dashboard" element={userAuth ? <Dashboard /> : <Navigate to="/login" replace />} />
          <Route path="/settings" element={userAuth ? <Settings /> : <Navigate to="/login" replace />} />
          <Route path="/profile" element={userAuth ? <Profile /> : <Navigate to="/login" replace />} />
          <Route path="/booking" element={userAuth ? <Booking /> : <Navigate to="/login" replace />} />
          <Route path="/users" element={userAuth ? <Users /> : <Navigate to="/login" replace />} />
          {/* <Route path="/pages" element={userAuth ? <Pages /> : <Navigate to="/login" replace />} /> */}
          <Route path="/blogs" element={userAuth ? <Blogs /> : <Navigate to="/login" replace />} />
          <Route path="/add-new-post" element={userAuth ? <AddNewPost /> : <Navigate to="/login" replace />} />
          <Route path="/edit-post/:id" element={userAuth ? <EditBlog /> : <Navigate to="/login" replace />} />
          <Route path="/archive-post" element={userAuth ? <ArchivePost /> : <Navigate to="/login" replace />} />
          <Route path="/single-post/:id" element={userAuth ? <SinglePost /> : <Navigate to="/login" replace />} />
          <Route path="/user-dashboard" element={userAuth ? <UserDashboard /> : <Navigate to="/login" replace />} />
          <Route path="/services" element={userAuth ? <Services /> : <Navigate to="/login" replace />} />
          <Route path="/add-service" element={userAuth ? <AddService /> : <Navigate to="/login" replace />} />
          <Route path="/categories" element={userAuth ? <Categories /> : <Navigate to="/login" replace />} />
          <Route path="/add-category" element={userAuth ? <AddCategory /> : <Navigate to="/login" replace />} />
          {/* Staff */}
          <Route path="/staff" element={userAuth ? <Staff /> : <Navigate to="/login" replace />} />
          <Route path="/add-staff" element={userAuth ? <AddStaff /> : <Navigate to="/login" replace />} />
          <Route path="/edit-staff/:id" element={userAuth ? <EditStaff /> : <Navigate to="/login" replace />} />
          {/* pages */}
          <Route path="/pages" element={userAuth ? <Pages /> : <Navigate to="/login" replace />} />
          <Route path="/add-new-page" element={userAuth ? <AddPage /> : <Navigate to="/login" replace />} />
          <Route path="/edit-page/:id" element={userAuth ? <EditPage /> : <Navigate to="/login" replace />} />

          {/* Pageview */}
          <Route path="/:slug" element={userAuth ? <ViewPage /> : <Navigate to="/login" replace />} />

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
