import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import AddOptions from '../Options/AddOptions';
import GetOptions from '../Options/GetOptions';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from 'react-bootstrap/esm/Spinner';
import TestValue from '../Options/TestValue';


function AdminSettings() {

  const [SetSiteName, setSiteName] = useState<any>(null);
  const [SetTagLine, setTagLine] = useState<any>(null);
  const [SetAdminEmail, setAdminEmail] = useState<any>(null);
  const [SetAnyonecanReg, setAnyonecanReg] = useState(false);
  const [SetDefaultRole, setDefaultRole] = useState<any>(null);
  const [SetWebsiteUrl, setWebsiteUrl] = useState<any>(null);
  const [SetFacebook, setFacebook] = useState<any>(null);
  const [SetInstagram, setInstagram] = useState<any>(null);
  const [SetTwitter, setTwitter] = useState<any>(null);
  const [SetLocation, setLocation] = useState<any>(null);
  const [SetMobileNumber, setMobileNumber] = useState<any>(null);
  const [SetEmailAddress, setEmailAddress] = useState<any>(null);
  const [SetHostName, setHostName] = useState<any>(null);
  const [SetPortNumber, setPortNumber] = useState<any>(null);
  const [SetUsername, setUsername] = useState<any>(null);
  const [SetHostPassword, setHostPassword] = useState<any>(null);

  const handleCheckboxChange = (event : any) => {
    setAnyonecanReg(event.target.checked); // Update state based on checkbox checked status
};

  const userMetaPairs = [
    {  option_name: 'sitename', setOptionsValue: setSiteName },
    {  option_name: 'tagline', setOptionsValue: setTagLine},
    {  option_name: 'adminemail', setOptionsValue: setAdminEmail },
    {  option_name: 'anyonecanreg', setOptionsValue: setAnyonecanReg },
    {  option_name: 'defaultrole', setOptionsValue: setDefaultRole },
    {  option_name: 'website_url', setOptionsValue: setWebsiteUrl },
    {  option_name: 'instagram', setOptionsValue: setInstagram },
    {  option_name: 'facebook', setOptionsValue: setFacebook },
    {  option_name: 'twitter', setOptionsValue: setTwitter },
    {  option_name: 'location', setOptionsValue: setLocation },
    {  option_name: 'mobile_number', setOptionsValue: setMobileNumber },
    {  option_name: 'email_us', setOptionsValue: setEmailAddress },
    {  option_name: 'hostname', setOptionsValue: setHostName },
    {  option_name: 'portnumber', setOptionsValue: setPortNumber },
    {  option_name: 'username', setOptionsValue: setUsername },
    {  option_name: 'hostpassword', setOptionsValue: setHostPassword },


]

TestValue();
  const { loading, error } = GetOptions(userMetaPairs);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    const data = {
      sitename: form.sitename.value,
      tagline: form.tagline.value,
      adminemail: form.adminemail.value,
      defaultrole: form.defaultrole.value,
      anyonecanreg: form.anyonecanreg.value,
      website_url: form.website_url.value,
      facebook: form.facebook.value,
      instagram: form.instagram.value,
      twitter: form.twitter.value,
      location: form.location.value,
      mobile_number: form.mobile_number.value,
      email_us: form.email_us.value,
      hostname: form.hostname.value,
      portnumber: form.portnumber.value,
      username: form.username.value,
      hostpassword: form.hostpassword.value,
    }
    console.log(data);

    AddOptions( 'sitename', data.sitename );
    AddOptions( 'tagline', data.tagline );
    AddOptions( 'adminemail', data.adminemail );
    AddOptions( 'defaultrole', data.defaultrole );
    AddOptions( 'anyonecanreg', data.anyonecanreg );
    AddOptions( 'website_url', data.website_url );
    AddOptions( 'facebook', data.facebook );
    AddOptions( 'instagram', data.instagram );
    AddOptions( 'twitter', data.twitter );
    AddOptions( 'location', data.location );
    AddOptions( 'mobile_number', data.mobile_number );
    AddOptions( 'email_us', data.email_us );
    AddOptions( 'hostname', data.hostname );
    AddOptions( 'portnumber', data.portnumber );
    AddOptions( 'username', data.username );
    AddOptions( 'hostpassword', data.hostpassword );

    toast("Data Uploaded");
    setValidated(true);
  };

  if (loading) {
    return <div>
      <div className='text-center mt-5 mb-5'>
       <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
      </Spinner>
      <p className='mt-3'><b>Loading...</b> Please wait.</p>
      </div>
    </div>; // Render loading indicator
}

  if (error) return <div>Error: {error}</div>;
  return (
    
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <ToastContainer></ToastContainer>
      <Row className="mb-3">
      <Form.Group className='col-md-6' controlId="SiteName">
          <Form.Label className='fw-semibold'>Site Title</Form.Label>
          <Form.Control 
          type="text"
          name='sitename'
          defaultValue={ SetSiteName }
          placeholder="Website Title" />
        </Form.Group>

        <Form.Group className='col-md-6' controlId="Tagline">
          <Form.Label className='fw-semibold'>Tagline</Form.Label>
          <Form.Control
          type="text"
          name='tagline'
          defaultValue={ SetTagLine }
          placeholder="Tagline" />
        </Form.Group>  
      </Row>

      <Row className="mb-3">
      <Form.Group className='col-md-6' controlId="email">
          <Form.Label className='fw-semibold'>Administration Email Address</Form.Label>
          <Form.Control
          type="email"
          name='adminemail'
          defaultValue={ SetAdminEmail }
          placeholder="info@website.com" />
        </Form.Group>

        <Form.Group className='col-md-6' controlId="formGridState">
          <Form.Label className='fw-semibold'>New User Default Role</Form.Label>
          <Form.Select name='defaultrole' defaultValue={SetDefaultRole}>
            <option value="homey_sales">Sales</option>
            <option value="homey_host">Host</option>
            <option value="homey_renter">Renter</option>
            <option value="shop_manager">Shop manager</option>
            <option value="customer">Customer</option>
            <option value="subscriber">Subscriber</option>
            <option value="contributor">Contributor</option>
            <option value="author">Author</option>
            <option value="editor">Editor</option>
            <option value="administrator">Administrator</option>
          </Form.Select>
        </Form.Group>
      </Row>

      <Form.Group className="mb-3" id="formGridCheckbox">
        <Form.Label className='fw-semibold'>Membership</Form.Label>
        <Form.Check 
        type="checkbox" 
        name='anyonecanreg'
        checked={SetAnyonecanReg}
        onChange={handleCheckboxChange}
        label="Anyone can register" />
      </Form.Group>

      <Row className="mb-3">
      <Form.Group className='col-md-12' controlId="website_url">
          <Form.Label className='fw-semibold'>Website Url</Form.Label>
          <Form.Control
          type="url"
          // id='website_url'
          name='website_url'
          defaultValue={SetWebsiteUrl}
          placeholder="https://mastershairbeaty.co/" />
        </Form.Group>
        </Row>


        <Row className="mb-3">
        <h6 className='mt-3 mb-3'><b>Social Media</b></h6>
          <Form.Group className='col-md-4' controlId="facebook">
              <Form.Label className='fw-semibold'>Facebook</Form.Label>
              <Form.Control
              type="url"
              // id='facebook'
              name='facebook'
              defaultValue={ SetFacebook }
              placeholder="https://facebook.com/pageurl/" />
            </Form.Group>

            <Form.Group className='col-md-4'>
      
              <Form.Label className='fw-semibold'>Instagram</Form.Label>
              <Form.Control
              type="url"
              name='instagram'
              // id='instagram'
              defaultValue={ SetInstagram }
              placeholder="https://instagram.com/pageurl/" />
            </Form.Group>

            <Form.Group className='col-md-4' controlId="twitter">
              <Form.Label className='fw-semibold'>Twitter / X</Form.Label>
              <Form.Control
              type="url"
              name='twitter'
              defaultValue={ SetTwitter }
              placeholder="https://twitter.com/pageurl/" />
            </Form.Group>
        </Row>

        <Row className="mb-3">
          <h6 className='mt-3 mb-3'><b>About Us</b></h6>
          <Form.Group className='col-md-4' controlId="Address">
              <Form.Label className='fw-semibold'>Location</Form.Label>
              <Form.Control
              type="text"
              // id='facebook'
              name='location'
              defaultValue={ SetLocation }
              placeholder="180 Seven Sisters Road, Finsbury Park, London N7 7PX" />
            </Form.Group>

            <Form.Group className='col-md-4' controlId="mobile_number">
      
              <Form.Label className='fw-semibold'>Mobile Number</Form.Label>
              <Form.Control
              type="tel"
              name='mobile_number'
              defaultValue={SetMobileNumber}
              placeholder="+020 7916 15021" />
            </Form.Group>

            <Form.Group className='col-md-4' >
              <Form.Label className='fw-semibold'>Email Address</Form.Label>
              <Form.Control
              type="email"
              name='email_us'
              defaultValue={SetEmailAddress}
              placeholder="info@mastershairbeaty.co" />
            </Form.Group>
          </Row>


          <Row className="mb-3">
            <h6 className='mt-3 mb-2'><b>Email Setup</b></h6>
            <Form.Group className='col-md-6' controlId="host">
              <Form.Label className='fw-semibold'>Host Name</Form.Label>
              <Form.Control
              type="text"
              name='hostname'
              defaultValue={ SetHostName }
              placeholder="client.mastershairbeaty.co" />
            </Form.Group>

            <Form.Group className='col-md-6' controlId="Port">
              <Form.Label className='fw-semibold'>Port Number</Form.Label>
              <Form.Control
              type="number"
              name='portnumber'
              defaultValue={SetPortNumber}
              placeholder="587" />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group className='col-md-6' controlId="user">
              <Form.Label className='fw-semibold'>Username</Form.Label>
              <Form.Control
              type="text"
              name='username'
              defaultValue={ SetUsername }
              placeholder="info@mastershairbeaty.co" />
            </Form.Group>

            <Form.Group className='col-md-6' controlId="Password">
              <Form.Label className='fw-semibold'>Password</Form.Label>
              <Form.Control
              type="text"
              name='hostpassword'
              defaultValue={ SetHostPassword }
              placeholder="*****" />
            </Form.Group>
          </Row>
        
      <Button variant="dark" type="submit">
        Save Setting
      </Button>
    </Form>
  );
}

export default AdminSettings;