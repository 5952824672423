import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface UsernameState {
    value: string;
}

const initialState: UsernameState = {
    value: '', // Initial state value for the username
};

const usernameSlice = createSlice({
    name: 'username',
    initialState,
    reducers: {
        setUsername: (state, action: PayloadAction<string>) => {
             state.value = action.payload; // Mutate the state to update the username value
        },
        resetUsername: (state) => {
            // Reset userAuth state to initial values by mutating the state object
            state.value = initialState.value;
        },
    },
});

export const { setUsername, resetUsername } = usernameSlice.actions;
export default usernameSlice.reducer;
