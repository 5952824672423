import axios from 'axios';
import BaseUrl from '../variable';
function UpdateUserMeta(userId: number, UserMeta : string, UserValue: string) {
    const data = {
        UserId: userId,
        UserMeta: UserMeta,
        UserValue: UserValue,
      };
      axios.post(`${BaseUrl}/api/user/updatemeta/`, data)
        .then(response => {
          console.log('User metadata updated successfully:', response.data);
          // You can perform additional actions after a successful update
        })
        .catch(error => {
          console.error('Error updating user metadata:', error);
          // Handle errors or display an error message to the user
        });
}

export default UpdateUserMeta;