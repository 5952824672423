import Typography from '@mui/material/Typography';
import Image from 'react-bootstrap/Image';
import BookingList from './BookingList';

import { RootState } from '../Redux/store';
import { useSelector } from 'react-redux';

function DashView() {
   
    const username = useSelector((state: RootState) => state.username.value);
    return (
        <div className='w-100 mt-5'>
            <div className='container-fluid'>
                <br />
                <br />
                <div className='ps-5 rounded-5 mt-5 mb-5 shadow-lg'>
                    <div className='row'>
                        <div className='col-lg-7 d-grid' style={{alignContent: "center"}}>
                        <Typography variant='h2' className='mb-3'>Hi,<span className='text-primary'>{username}</span></Typography>
                        <Typography paragraph>
                        Ready to start your day with some pitch decks?
                        </Typography>
                    </div>
                    <div className='col-lg-5'>
                    <Image 
                    src='https://elements-cover-images-0.imgix.net/727091cf-69db-4e6f-8f69-ee77b851091a?auto=compress%2Cformat&w=900&fit=max&s=4a41d20e8e033fe43261cea7130a6bbf' 
                    thumbnail style={{marginTop: "-30px", marginBottom: "-30px"}} className='rounded-5' />
                    </div>
                    </div>
                </div>
                <br />
                <br />
                <div className='ps-5 mb-2 shadow-lg p-5 rounded-5 mt-2'>
                    <BookingList />
                </div>
                <br />
                <br />
                <div className='ps-5 mb-5 shadow-lg p-5 rounded-5 mt-2'>
                    <p className='text-center mt-3'>No Booking has been found so far. if you not made any booking <a href="">Book Now!!!</a></p>
                </div>

            </div>
        </div>
    );
}

export default DashView;