import React from 'react';
import ThemeContainer from '../../common/ThemeContainer';
import { Link } from 'react-router-dom';
import ServiceList from './ServiceList';

function index() {
    return (
        <div>
            <ThemeContainer>
            <h4 className='mt-3 mb-4 fw-bold text-start'>Services List</h4>
            <div>
            <Link className='btn btn-outline-dark me-3' to='/add-service'>Add New Service</Link>
            </div>
            <ServiceList></ServiceList>
            </ThemeContainer>
        </div>
    );
}

export default index;