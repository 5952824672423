import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import BaseUrl from '../variable';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import ThemeContainer from '../../common/ThemeContainer';
import { Container } from '@mui/material';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import ReactQuill from 'react-quill';
import Form from 'react-bootstrap/esm/Form';

interface Staff {
    id: number;
    title: string;
    body: string;
    author: string;
    createdAt: string;
    featured_img: string;
}

const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false,
    }
  }
  
  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ]
  
function EditStaff() {
    const [file, setFile] = useState<File | null>(null);
    const [Path, setImagePath] = useState<string | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [setFeaturedPath, setPath] = useState('');
    const { id } = useParams<{ id: string }>();


    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0];
        if (selectedFile) {
          setFile(selectedFile);
          setPreviewUrl(URL.createObjectURL(selectedFile));
          const config = {
              headers: { 'content-type': 'multipart/form-data' }
          };
          const formData = new FormData();
          formData.append("image", selectedFile);
          const response = await axios.post(`${BaseUrl}/api/upload`, formData , config);
          if (response.status === 200) {
            setImagePath(response.data.imagePath)
            toast.success("Image uploaded successfully!");
          } else {
            toast.error("Error uploading image!");
          }
        }
      };
    useEffect(() => {
        axios.get<Staff>(`${BaseUrl}/api/staff/single/${id}`)
          .then(response => {
            const staffData = response.data;
            setTitle(staffData.title);
            setBody(staffData.body);

            setPath(staffData.featured_img);
          })
          .catch(error => {
            console.error('Error fetching staff:', error);
          });
      }, [id]);


      const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
      }
      
      const handleBodyChange = (value: string) => {
        setBody(value);
      }
      const handleUpdateStaff = () => {
        const data = {
            id : id,
            title : title,
            body : body,
            featured_img : Path
    
        }
        axios.post(`${BaseUrl}/api/staff/update`, data)
        .then(response => {
          if (response.status === 200) {
            toast(response.data.msg);
          } }
          ).catch((err) => console.log(err));
          console.log(data)
    }

    return (
        <div>
            <ToastContainer />
      <ThemeContainer>
        <Container>
          <Row>
            <Col lg={8}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" required name='title' value={title} onChange={handleTitleChange} placeholder="Add title" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Content</Form.Label>
                <ReactQuill style={{'height':'380px'}}
                  modules={modules}
                  id='body'
                  theme="snow"
                  value={body}
                  formats={formats}
                  onChange={handleBodyChange}
                />
              </Form.Group>
              <br />
              <br />
              <br />
            </Col>
            <Col lg={4}>
            <button className='btn btn-success mb-3 me-3' onClick={handleUpdateStaff}>Update</button>
            <br />
                <img src={`${BaseUrl}${setFeaturedPath}`} alt=""  width={'100%'}  />
                {previewUrl && <img width={'100%'} 
                className='rounded'  src={previewUrl} alt="Preview" />}
                <hr />
                <input accept="image/png, image/gif, image/jpeg"  type="file" name="featured_img" onChange={handleFileChange} />
            </Col>
          </Row>
        </Container>
      </ThemeContainer>
        </div>
    );
}

export default EditStaff;