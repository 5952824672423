import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { ToastContainer, toast } from 'react-toastify';
import UpdateUserMeta from './UpdateUserMeta'
import GetUserMeta from './GetUserMeta';
import Spinner from 'react-bootstrap/esm/Spinner';

function FormExample() {
  const [metaValue1, setMetaValue1] = useState<any>(null);
  const [metaValue2, setMetaValue2] = useState<any>(null);
  const [Nickname, setMetaValue3] = useState<any>(null);
  const [Username, UserName] = useState<any>(null);
  
  const userMetaPairs = [
      { userId: 1, metaKey: 'firstname', setMetaValue: setMetaValue1 },
      { userId: 1, metaKey: 'lastname', setMetaValue: setMetaValue2 },
      { userId: 1, metaKey: 'nickname', setMetaValue: setMetaValue3 },
      { userId: 1, metaKey: 'username', setMetaValue: UserName },
  ];

    const { loading, error } = GetUserMeta(userMetaPairs);  
    const [validated, setValidated] = useState(false);
    const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    
    const data = {
      firstname: event.currentTarget.firstname.value,
      lastname: event.currentTarget.lastname.value,
      nickname: event.currentTarget.nickname.value,
      username: event.currentTarget.username.value
    }
    UpdateUserMeta(1, 'firstname', data.firstname );
    UpdateUserMeta(1, 'lastname', data.lastname );
    UpdateUserMeta(1, 'nickname', data.nickname );
    UpdateUserMeta(1, 'username', data.username );
    
    toast("Data Uploaded");
    setTimeout(() => {
      window.location.reload(); 
    }, 4000);
    setValidated(true);
  };

  if (loading) {
    return <div>
      <div className='text-center mt-5 mb-5'>
       <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
      </Spinner>
      <p className='mt-3'><b>Loading...</b> Please wait.</p>
      </div>
    </div>; // Render loading indicator
}
  if (error) return <div>Error: {error}</div>;
  return (
    
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <ToastContainer />
      <Row className="mb-4">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>First name</Form.Label>
          <Form.Control
            required
            type="text"
            name='firstname'
            placeholder="Mark"
            // value=""
            defaultValue={ metaValue1 }
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Last name</Form.Label>
          <Form.Control
            required
            type="text"
            name='lastname'
            placeholder="Otto"
            defaultValue={ metaValue2 }
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustomUsername">
          <Form.Label>Username</Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Username"
              aria-describedby="inputGroupPrepend"
              required
              defaultValue={ Username }
              name='username'
            />
            <Form.Control.Feedback type="invalid">
              Please choose a username.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row className="mb-4">
        <Form.Group as={Col} md="6">
          <Form.Label>Nickname (required)</Form.Label>
          <Form.Control 
          type="text" 
          placeholder="Nickname (required)" 
          name='nickname'
          defaultValue={ Nickname }
          required />
         
        </Form.Group>
        <Form.Group as={Col} md="3">
          <Form.Label>Password</Form.Label>
          <Form.Control 
          type="password" 
          placeholder="Password"
          name='password'
          required />
        </Form.Group>
        <Form.Group as={Col} md="3">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control 
          type="password"
          placeholder="Confirm Password"
          name='confirmpassword'
          required />
        </Form.Group>
      </Row>
      <Button type="submit" className='btn btn-dark'>Save Profile</Button>
    </Form>
  );
}

export default FormExample;