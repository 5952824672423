import { Box, IconButton, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { ButtonInput } from './Components/types';
import BaseUrl from '../../variable';
import axios from 'axios';
import { toast } from 'react-toastify';
import DynamicButtonInputs from './Components/DynamicButtonInputs';
import DeleteIcon from '@mui/icons-material/Delete'; // Import delete icon
interface CenterBlockViewProps {
  title: string;
  subheading: string;
  setImagePath: (imagePath: string) => void; // Corrected the type
  passBtnValue: ButtonInput[]; // Corrected the type
  passImageValue: string;
  onHeadingChange: (newHeading: string) => void;
  onSubheadingChange: (newSubheading: string) => void;
  buttonValueChange: (values: ButtonInput[]) => void;
  }
  
function CenterBlock(props : CenterBlockViewProps) {
  const { title, subheading, onSubheadingChange, onHeadingChange, buttonValueChange, passBtnValue, passImageValue, setImagePath } = props;
  const [previewUrl, setPreviewUrl] = useState<string | null>(passImageValue ? `${BaseUrl}${passImageValue}` : null);
  const [file, setFile] = useState<File | null>(null);
  const [Title, setTitle] = useState(title || '');
  const [Subheading, setSubheading] = useState(subheading || '');
  
  
    useEffect(() => {
      setTitle(title);
      setSubheading(subheading);
    }, [title, subheading]);
  
    const handleHeadingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newTitle = event.target.value;
      setTitle(newTitle);
      onHeadingChange(newTitle); // Call the callback function with the new Title value
    };
  
    const handleSubheadingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newSubheading = event.target.value;
      setSubheading(newSubheading);
      onSubheadingChange(newSubheading); // Call the callback function with the new Subheading value
    };
  
    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = e.target.files && e.target.files[0];
      if (selectedFile) {
        setFile(selectedFile);
        const formData = new FormData();
        formData.append("image", selectedFile);
        try {
          const response = await axios.post(`${BaseUrl}/api/upload`, formData);
          if (response.status === 200) {
            const imagePath = response.data.imagePath;
            setImagePath(imagePath);
            setPreviewUrl(`${BaseUrl}${imagePath}`);
            toast.success("Image uploaded successfully!");
          } else {
            toast.error("Error uploading image!");
          }
        } catch (error) {
          toast.error("Error uploading image!");
        }
      }
    };
  
    const handleRemoveImg = async (passImageValue: string) => {
      try {
        const filename = passImageValue.substring(passImageValue.lastIndexOf('/') + 1);
        const response = await axios.delete(`${BaseUrl}/api/remove/img/${filename}`);
        if (response.status === 200) {
          setImagePath(''); // Clear imagePath in case of successful deletion
          setPreviewUrl(null);
          toast.success("Image deleted successfully!");
        } else {
          toast.error("Error deleting image!");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Error deleting image!");
      }
    };
    return (
        <div>
            <div className='p-3 mt-2 mb-3 rounded-4 bg-darklight'>
            <form>
                <p><b>Center Block</b></p>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '400px' }}>
                    <TextField
                        label="Heading"
                        name="heading"
                        className='bg-white'
                        value={Title}
                        onChange={handleHeadingChange}
                        variant="outlined" />
                    <TextField
                        label="Sub Heading"
                        name="subheading"
                        className='bg-white'
                        value={Subheading}
                        onChange={handleSubheadingChange}
                        rows={3}
                        multiline
                        variant="outlined" />

            <p><b>Section Image</b></p>
            {passImageValue && <div style={{ position: "relative",maxWidth:"350px"}}>
              <img src={`${BaseUrl}${passImageValue}`} className='rounded-3' alt=""  width={'100%'}  style={{}} />
                <IconButton className="bg-danger" onClick={() => handleRemoveImg(passImageValue)} style={{ position: "absolute", top: "5px", right: "5px" }}>
                    <DeleteIcon className="text-white" />
                </IconButton>
                <hr />
            </div>}
            {!passImageValue &&  <input
              accept="image/png, image/gif, image/jpeg"
              type="file"
              name="featured_img"
              onChange={handleFileChange}
            />}
            <DynamicButtonInputs sendValuesToParent={buttonValueChange} initialButtonInputs={passBtnValue} />
                  
                </Box>
                </form>
        </div>
        </div>
    );
}

export default CenterBlock;