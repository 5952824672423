import { useState, useEffect } from 'react';
import axios from 'axios';
import BaseUrl from '../variable';

type UserMetaPair = {
    option_name: string;
    setOptionsValue: (value: any) => void;
  };
function GetOptions(userMetaPairs: UserMetaPair[]) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
  
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
  
            try {
                const promises = userMetaPairs.map(async ({ option_name, setOptionsValue }) => {
                    const response = await axios.post(`${BaseUrl}/api/getoptions/`, {
                        option_name: option_name,
                    });
                    setOptionsValue(response.data[0]?.option_value);
                });
                
                await Promise.all(promises);
                setLoading(false); // Set loading to false after data is fetched
            } catch (error) {
                setError('Error fetching data');
                setLoading(false); // Set loading to false in case of error
            }
        };
  
        fetchData();
    }, []); // Removed userMetaPairs from dependencies to fetch data only once
  
    return { loading, error };
}

export default GetOptions;