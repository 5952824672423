import ThemeContainer from '../../common/ThemeContainer';
import { Container, Grid, Paper } from '@mui/material';
import Deposits from '../../components/Deposits';
import Sales from '../../components/Sales';
import Spend from '../../components/Spend';
import DashboardBooking from './Booking';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import './dashboard.css'


const drawerWidth: number = 300;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiToolbar-root' : {
      background: '#f9f9f9',
    },
    '& .MuiDrawer-paper': {
      position: 'relative',
      background: '#f9f9f9',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
      
      '& .css-mv42r1-MuiPaper-root' : {
        padding: '20px 15px',
        height : '360px',
      },
      '& .MuiListItemIcon-root' : {
        paddingLeft: '15px',
      },
      '& .css-cveggr-MuiListItemIcon-root' : {
        color: 'rgb(255 255 255 / 54%)',
      },
      '& .MuiButtonBase-root' : {
        color: 'rgb(255 255 255 / 54%)',
        padding: '10px',
        margin: '10px',
      },
      '& .MuiSvgIcon-fontSizeMedium' :  {

      },
    },
  }),
);



function dashboard() {
  return (
    <ThemeContainer>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <h4 className='mt-4 mb-4 fw-bolder text-start' >Dashboard</h4>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <Paper
                    sx={{
                      boxShadow : 'none',
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '15px',
                    }}
                  >
                    <Deposits />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Paper
                    sx={{
                      boxShadow : 'none',
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '15px',

                    }}
                  >
                    <Spend />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Paper
                    sx={{
                      boxShadow : 'none',
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '15px',

                    }}
                  >
                    <Sales />
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Paper sx={{ display: 'flex', flexDirection: 'column', boxShadow : 'none', 
                      borderRadius: '15px',
                    }}>
                    <DashboardBooking />
                  </Paper>
                </Grid>
              </Grid>
            </Container>
    </ThemeContainer>
  );
}

export default dashboard;