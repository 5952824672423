import {jwtDecode} from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUsername } from '../../Redux/usernameSlice'; 
import { setToken } from "../../Redux/tokenSlice";
import { AppDispatch, RootState } from '../../Redux/store';
import { setUserRole } from "../../Redux/userRoleSlice";
import BaseUrl from "../../variable";

interface DecodedToken {
    name: string;
    refresh_token: string;
    userId: string;
    exp: number; // Assuming `exp` is a number representing expiration time
    // Add other properties as needed
}

function AuthUser() {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state: RootState) => state.token.value);
    
    useEffect(() => {
        refreshToken();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${BaseUrl}/token`, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const decoded: DecodedToken = jwtDecode(response.data.accessToken);
            dispatch(setUsername(decoded.name));
            dispatch(setToken(decoded.refresh_token));
            dispatch(setUserRole("administrator"));
            navigate("/dashboard");            
        } catch (error: any) { // Specify error type as `any`
            if (error.response) {
                navigate("/login");  
            }
        }
    }

    return null; // This component doesn't render anything
}

export default AuthUser;
