import React, {  useState } from 'react';
import ThemeContainer from '../../common/ThemeContainer';
import BaseUrl from '../variable';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TextField } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import PageReorder from './PageReorder';
import useDebounce from './Parts/Components/useDebounce';

function AddPage() {
    
    const [pageTitle, setPageTitle] = useState<string>('');
    const [pageSlug, setPageSlug] = useState<string>('');
    const [BodyData, setBodyData] = useState<string>('');
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const debouncedUpdateState = useDebounce((updatedTitle: string, updatedSlug: string) => {
        setPageTitle(updatedTitle);
        setPageSlug(updatedSlug);
    }, 1500);
    
    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedTitle = event.target.value;
        const updatedSlug = slugify(updatedTitle); // Convert title to slug
        debouncedUpdateState(updatedTitle, updatedSlug);
    }
    // Function to convert title to slug
    const slugify = (title: string) => {
        return title.trim().toLowerCase().replace(/\s+/g, '-');
    };

    function handlePageBody(body: any) {
        setBodyData(body.body);
    }
    const PageData = {
        title: pageTitle,
        slug: pageSlug,
        body: BodyData
    }

    const handleUpdate = async () => {
        if (!pageTitle.trim()) {
            // Alert or handle the empty field case here
            alert('Please fill in the Page Title field.');
            return; // Exit the function early
        }
        const url = `${BaseUrl}/api/post/posttopagesdb`;
        const requestData = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(PageData),
            // body: JSON.stringify(BodyData)
        };
        
        try {
            const response = await fetch(url, requestData);
            if (response.ok) {
                toast.success('Page created successfully');
                setTimeout(() => {
                    navigate("/pages")
                }, 3000);
            } else {
                toast.error('Failed to create page data');
            }
        } catch (error : any) {
            toast.error('Error occurred while updating page data:', error);
        }
    };
    
    return (
        <>
        <ToastContainer></ToastContainer>
        <ThemeContainer>
            <div className='row'>
                <div className='col-lg-8 no-list'>
                    <PageReorder sendSetPagedata={function (body: any): void { handlePageBody(body); } } pageValue={undefined} />
                </div>
                <div className='col-lg-4'>
                    <div className='bg-darklight pt-5 pb-5 p-3 rounded-4'>
                        <Link style={{ textDecoration: 'none' }} to='/add-new-page' className='btn btn-outline-dark mb-3'>
                            Add a new page
                        </Link>
                        <br />
                        <br />
                        <TextField
                        required
                            label='Page Title'
                            name='heading'
                            className='bg-white w-100 mb-3'
                            variant='outlined'
                            onChange={handleTitleChange}
                        />
                        <TextField
                            label='Page Slug'
                            name='slug'
                            value={pageSlug}
                            className='bg-white w-100 mb-3'
                            variant='outlined'
                        />
                        <button className='btn btn-success me-3' onClick={handleUpdate}>  Publish Page  </button>
            </div>
          </div>
        </div>
      </ThemeContainer>
      </>
    );
}

export default AddPage;