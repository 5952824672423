import ThemeContainer from '../../common/ThemeContainer';
import { Link } from 'react-router-dom';
import StaffMembers from './StaffMembers';
function index() {
    return (
       <ThemeContainer>
            <h4 className='mt-3 mb-4 fw-bold text-start'>Staff Members</h4>
            <div>
            <Link className='btn btn-outline-dark me-3' to='/add-staff'>Add New Staff Member</Link>
            </div>
            <StaffMembers></StaffMembers>
       </ThemeContainer>
    );
}

export default index;