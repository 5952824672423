import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ThemeContainer from '../../common/ThemeContainer';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface Service {
  name: string;
  description: string;
  prices: {
    ladies: string;
    ladies_oap: string;
    children: string;
    men:string;
  };
}

const initialServiceState: Service = {
  name: '',
  description: '',
  prices: {
    ladies: '',
    ladies_oap: '',
    children: '',
    men: '',
  },
};

const SalonServiceForm: React.FC = () => {
  const [service, setService] = useState<Service>(initialServiceState);
  const [priceType, setPriceType] = useState<keyof Service['prices'] | undefined>(undefined);
  const [categories, setCategories] = useState<{ id: number; name: string }[]>([]);

  useEffect(() => {
    // Fetch categories from the API
    fetch('https://client.mastershairbeaty.co/getc')
      .then(response => response.json())
      .then(data => setCategories(data))
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target;
    if (name === 'priceType') {
      setPriceType(value as keyof Service['prices']);
    } else if (priceType !== undefined) {
      setService((prevService) => ({
        ...prevService,
        prices: {
          ...prevService.prices,
          [priceType as string]: value as string, // Dynamically set the key based on selected price type
        },
      }));
    }
  };
  
  const navigate = useNavigate();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    // Handle form submission
    fetch('https://client.mastershairbeaty.co/createService', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: service.name,
        description: service.description,
        price_ladies: parseFloat(service.prices.ladies),
        price_ladies_oap: parseFloat(service.prices.ladies_oap),
        price_children: parseFloat(service.prices.children),
        price_men: parseFloat(service.prices.men)
      }),
    })
    .then(response => {
      if (response.ok) {
        toast.success('Service inserted successfully');
        setService(initialServiceState);
        setPriceType(undefined);
        setTimeout(() => {
          navigate('/services');
        }, 3000);
      } else {
        console.error('Failed to insert service');
      }
    })
    .catch(error => console.error('Error inserting service:', error));
  };
  
  

  return (
<ThemeContainer>
<ToastContainer></ToastContainer>
  <h3 className='mt-3'>Add New Service</h3>
  <br />
    <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '400px' }}>
            <FormControl variant="outlined">
            <InputLabel id="categoryLabel">Category</InputLabel>
            <Select
            required
                labelId="categoryLabel"
                id="category"
                name="category"
                value={service.name || ''}
                onChange={(e) => setService((prevService) => ({ ...prevService, name: e.target.value }))}
                label="Category"
            >
                {categories.map((category) => (
                <MenuItem key={category.id} value={category.name}>
                    {category.name}
                </MenuItem>
                ))}
            </Select>
            </FormControl>
            <TextField
            required
            label="Description"
            name="description"
            value={service.description}
            onChange={(e) => setService((prevService) => ({ ...prevService, description: e.target.value }))}
            variant="outlined"
            multiline
            rows={4}
            />
            <FormControl variant="outlined">
            <InputLabel id="priceTypeLabel">Price Type</InputLabel>
            <Select
            required
                labelId="priceTypeLabel"
                id="priceType"
                name="priceType"
                value={priceType || ''}
                onChange={(e) => setPriceType(e.target.value as keyof Service['prices'])}
                label="Price Type"
            >
                <MenuItem value="ladies">Ladies</MenuItem>
                <MenuItem value="ladies_oap">Ladies (OAP)</MenuItem>
                <MenuItem value="children">Children</MenuItem>
                <MenuItem value="men">Men</MenuItem>
            </Select>
            </FormControl>
            {priceType !== undefined && (
            <TextField
            required
                label="Price"
                name={priceType}
                value={service.prices[priceType]}
                onChange={handleChange}
                variant="outlined"
            />
            )}
            <Button type="submit" variant="contained" color="primary">
            Submit
            </Button>
        </Box>
        </form>
</ThemeContainer>
  );
};

export default SalonServiceForm;