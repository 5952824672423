import axios from 'axios';
import BaseUrl from '../variable';
function AddOptions( OptionName : string, OptionValue: string) {
    const data = {
        OptionName: OptionName,
        OptionValue: OptionValue,
      };
      axios.post(`${BaseUrl}/api/addoptions/`, data)
        .then(response => {
          console.log('Options Added', response.data);
          // You can perform additional actions after a successful update
        })
        .catch(error => {
          console.error('Error updating user metadata:', error);
          // Handle errors or display an error message to the user
        });
}
export default AddOptions;