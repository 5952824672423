import ThemeContainer from '../../common/ThemeContainer';
import BookingList from './BookingList';

function index() {
  return (
    <div>
      <ThemeContainer>
      <h4 className='mt-3 mb-4 fw-bold text-start'>Booking List</h4>
            <div>
            </div>
         <BookingList />
      </ThemeContainer>
    </div>
  );
}

export default index;