import React, { useEffect, useState } from 'react';
import ThemeContainer from '../../common/ThemeContainer';
import BaseUrl from '../variable';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TextField } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setPageValue } from '../Redux/pageValuesSlice';
import PageReorder from './PageReorder';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

function EditPage() {
  const [pageData, setPageData] = useState<any>();
  const [pageTitle, setPageTitle] = useState<string>('');
  
  const [pageSeoTitle, setPageSeoTitle] = useState<string>('');
  const [pageSeoDescription, setPageSeoDescription] = useState<string>('');
  const [pageSeoKeywords, setPageSeoKeywords] = useState<string>('');
  
  const [pageSlug, setPageSlug] = useState<string>('');
  const [pageView, setPageView] = useState<string>('default');
  const [BodyData, setBodyData] = useState<any>('');
  const { id } = useParams<{ id: string }>(); 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    fetch(`${BaseUrl}/api/page/single/${id}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        return response.json();
      })
      .then(data => {
        data.body = JSON.parse(data.body);
        setPageTitle(data.title);
        setPageSlug(data.slug);
        setBodyData(data.body);
        setPageView(data.pageview);
        setPageSeoTitle(data.seotitle);
        setPageSeoDescription(data.seodescription);
        setPageSeoKeywords(data.seokeywords);
        setPageData(data);
      })
      .catch(error => {
        console.error('Error fetching data:', error.message);
      });
  }, [id]);
  

  const handleSlugChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedSlug = event.target.value;
    setPageData({ ...pageData, slug: updatedSlug });
    setPageSlug(updatedSlug)
  };
  const handlePageViewChange = ( event: SelectChangeEvent) => {
    const updatedPageview = event.target.value;
    setPageData({ ...pageData, pageview: updatedPageview });
    setPageView(updatedPageview)
  };

  const handleSeoTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedSeoTitle = event.target.value;
    setPageData({ ...pageData, seotitle: updatedSeoTitle });
    setPageSeoTitle(updatedSeoTitle);
  };
  
  const handleSeoDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedSeoDescription = event.target.value;
    setPageData({ ...pageData, seodescription: updatedSeoDescription });
    setPageSeoDescription(updatedSeoDescription);
  };

  const handleSeoKeywordsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedSeoKeywords = event.target.value;
    setPageData({ ...pageData, seokeywords: updatedSeoKeywords });
    setPageSeoKeywords(updatedSeoKeywords);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedTitle = event.target.value;
    setPageData({ ...pageData, title: updatedTitle });
    setPageTitle(updatedTitle);
  };


  const handleUpdate = async () => {
    const url = `${BaseUrl}/api/pages/update`;
    const requestData = {
      method: 'POST', // Assuming you're sending a POST request
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(pageData)
    }
    
    try {
      const response = await fetch(url, requestData);
      if (response.ok) {
        
        toast.success('Page data updated successfully');
        setTimeout(() => {
            navigate("/pages")
        }, 3000);

        dispatch(setPageValue(BodyData)); // Optionally, you can handle the response data here
      } else {
        toast.error('Failed to update page data');
      }
    } catch (error) {
      console.error('Error occurred while updating page data:', error);
    }
  };


  const handlePathChange = async (imgPath: string, index: number) => {
    if (!pageData || !pageData.body) return;
    
    // Update the image path in the page data
    const updatedBody = [...pageData.body];
    updatedBody[index] = { ...updatedBody[index], img: imgPath };
    setPageData({ ...pageData, body: updatedBody });
    handleImageUpdate(pageData,updatedBody); // Pass the entire pageData object to handleImageUpdate
  };
  
  const handleImageUpdate = async (pageData: any, updatedBody: any[]) => {
    const url = `${BaseUrl}/api/pages/update`;
    const updatedPageData = { ...pageData, body: updatedBody }; // Update the body property in pageData
    
    const requestData = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(BodyData) // Use updatedPageData instead of pageData
    };
  
    try {
      const response = await fetch(url, requestData);
      if (response.ok) {
        toast.success('Page data updated successfully'); // Optionally, you can handle the response data here
      } else {
        toast.error('Failed to update page data');
      }
    } catch (error) {
      console.error('Error occurred while updating page data:', error);
    }
  };
  function handlePageBody(body: any) {
     const Body = body.body
     const updatedPageData = { ...pageData, body: Body };
     setPageData(updatedPageData);
     setBodyData(updatedPageData);
  }
  console.log(pageData)
  return (
    <div>
      <ToastContainer></ToastContainer>
      <ThemeContainer>
        <div className='row'>
          <div className='col-lg-8 no-list'>
            <PageReorder
            pageValue={pageData}
            sendSetPagedata={function (body: any): void {  handlePageBody(body); } }  />
          </div>
          <div className='col-lg-4'>
            <div className='bg-darklight pt-5 pb-5 p-3 rounded-4'>
              <Link style={{ textDecoration: 'none' }} to='/add-new-page' className='btn btn-outline-dark mb-3'>
                Add a new page
              </Link>
              <br />
              <br />
              <TextField
                label='Page Title'
                name='heading'
                value={pageTitle}
                className='bg-white w-100 mb-3'
                variant='outlined'
                onChange={handleTitleChange}
              />
            <TextField
                label='Page Slug'
                name='slug'
                value={pageSlug}
                className='bg-white w-100 mb-3'
                variant='outlined'
                onChange={handleSlugChange}
              />
              <FormControl fullWidth className="mb-4 bg-white">
                <InputLabel id="page-view">Page View</InputLabel>
                <Select
                  labelId="page-view"
                  id="page-view"
                  value={pageView}
                  label="Page View"
                  onChange={handlePageViewChange}
                >
                  <MenuItem value="default">Default View</MenuItem>
                  <MenuItem value="accordion">Accordion View</MenuItem>
                  <MenuItem value="contentview">Content View</MenuItem>
                </Select>
              </FormControl>
              <p><b>SEO Feilds</b></p>
              <TextField
              label="Title"
              fullWidth
              className='bg-white mb-3'
              value={pageSeoTitle}
              onChange={handleSeoTitleChange}
              name="seotitle"
              variant="outlined"
            />
              <TextField
              label="Description"
              fullWidth
              className='bg-white mb-3'
              value={pageSeoDescription}
              onChange={handleSeoDescriptionChange}
              name="seodescription"
              variant="outlined"
              multiline
              rows={3}
            />
            <TextField
              label="Keywords"
              fullWidth
              className='bg-white mb-3'
              // value={subtitle}
              onChange={handleSeoKeywordsChange}
              value={pageSeoKeywords}
              name="seokeywords"
              placeholder='Music, Audio, Lyrics'
              variant="outlined"
              multiline
              rows={3}
            />
              <button className='btn btn-success me-3' onClick={handleUpdate}>
                Update Page
              </button>
              
            </div>
          </div>
        </div>
      </ThemeContainer>
    </div>
  );
}

export default EditPage;
