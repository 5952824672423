import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import ThemeContainer from '../../common/ThemeContainer';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";
import BaseUrl from '../variable';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Container from 'react-bootstrap/esm/Container';

const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
}
const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ]

const AddBlogs = () => {
const [file, setFile] = useState<File | null>(null);
const [previewUrl, setPreviewUrl] = useState<string | null>(null);
const [Path, setImagePath] = useState<string | null>(null);

const [ContentValue, setValue] = useState('');
const navigate = useNavigate();
function handleSubmit(event : any) {
  
  event.preventDefault();
  
  const data = {
    title: event.target.title.value,
    body: ContentValue,
    status: event.target.status.value,
    user_id: event.target.user_id.value,
    user_name: event.target.user_name.value,
    featured_img : Path
  }
 
  axios.post(`${BaseUrl}/api/post/posttodb`, data)
  .then(response => {
    if (response.status === 200) {
      toast.success(response.data.msg);
      setTimeout(() => {
      navigate('/blogs');
    }, 5000)
    }
  })
  .catch((err) => console.log(err));
  console.log(data);
}


const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  const selectedFile = e.target.files && e.target.files[0];
  console.log(selectedFile)
  if (selectedFile) {
    setFile(selectedFile);
    setPreviewUrl(URL.createObjectURL(selectedFile));
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    };
    const formData = new FormData();
    formData.append("image", selectedFile);
    const response = await axios.post(`${BaseUrl}/api/upload`, formData , config);
    if (response.status === 200) {
      setImagePath(response.data.imagePath)
      toast("Image uploaded successfully!");
    } else {
      toast("Error uploading image!");
    }
  }
};


  return (
    <div>
      <ThemeContainer>
      <Container>
      <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={8}>
        <h4>Add a New Post</h4>
        <br />
        <ToastContainer />
        
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Title</Form.Label>
            <Form.Control type="text" required name='title' placeholder="Add title" />
            <Form.Control type="hidden" required name='user_id' value={'1'} />
            <Form.Control type="hidden" required name='user_name' value={'Admin'}  />

          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Content</Form.Label>
            <ReactQuill style={{'height':'300px'}}
            modules={modules} 
            id='body'
            theme="snow" 
            value={ContentValue} 
            formats={formats}
            placeholder={'Write something...'}
            onChange={setValue}  />
          </Form.Group>
          <br />      
          <br />      
          <br />      
        </Col>
        <Col lg={4}>
        <br /><br />
          <Form.Select aria-label="publish" name='status'>
            <option value="publish">Published</option>
            <option value="pending">Pending Review</option>
            <option value="draft">Draft</option>
          </Form.Select>
          <br />
          
          <button
          type='submit' style={{'width': '150px'}} 
          className='btn btn-success'>Upload Post</button>

          {previewUrl && <img width={'100%'}
          className='rounded'  src={previewUrl} alt="Preview" />}
          <hr />
          <input type="file" name="featured_img" onChange={handleFileChange} />
        </Col>
          </Row>
          </Form>
        </Container>
      </ThemeContainer>
    </div>
  );
};

export default AddBlogs;
