import { Box, TextField } from '@mui/material';
import React, { useState } from 'react';
import AccordionComponent from './Parts/AccordionComponent';
import { AccordionInput } from './Parts/Components/types';
interface AccordionProps {
    pageValue: {
        heading: string;
        subheading: string;
        AccordionValue: any
    };
  onHeadingChange: (newTitle: string) => void; // Function to handle heading change in parent
  onSubHeadingChange: (newSubTitle: string) => void; // Function to handle heading change in parent
  AccordionSectionValueChange: (AccordionValue: any) => void;
}

const AccordionSection: React.FC<AccordionProps> = ({ pageValue, onHeadingChange, onSubHeadingChange, AccordionSectionValueChange}) => {
    const heading = pageValue.heading || '';
    const subheading = pageValue.subheading || '';
    const [title, setTitle] = useState<string>(heading);
    const [subtitle, setSubTitle] = useState<string>(subheading);

    const handleHeadingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        const newTitle = event.target.value;
        setTitle(newTitle);
        onHeadingChange(newTitle); // Send new title to the parent component
      };
    
      const handleSubHeadingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSubTitle = event.target.value;
        setSubTitle(newSubTitle);
        onSubHeadingChange(newSubTitle); // Send new title to the parent component
      };

  return (
    <div>
      <div className='mt-3 p-3 rounded-4 bg-darklight'>
        <form>
          <p><b>Accordion Section</b></p>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '100%' }}>
            <TextField
              className='bg-white'
              label="Heading"
              name="heading"
              value={title}
              onChange={handleHeadingChange}
              variant="outlined"
            />
            <TextField
              label="Sub Heading"
              className='bg-white'
              value={subtitle}
              onChange={handleSubHeadingChange}
              name="subheading"
              variant="outlined"
              multiline
              rows={3}
            />
            <AccordionComponent 
            AccordionValueChange={AccordionSectionValueChange} pageValue={pageValue} />
          </Box>
        </form>
      </div>
    </div>
  );
}

export default AccordionSection;