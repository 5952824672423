import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import DynamicAccordion from './Components/DynamicAccordion';

interface AccordionInput {
  question: string;
  answer: string;
}

interface AccordionComponentProps {
  AccordionValueChange: (components: { acc_title: string; content: AccordionInput[] }[]) => void;
  pageValue: any;
}

const AccordionComponent: React.FC<AccordionComponentProps> = ({ AccordionValueChange, pageValue }) => {
  const AccordionValue = pageValue.AccordionValue;
  const [accordionSectionComponents, setAccordionSectionComponents] = useState<{ acc_title: string; content: AccordionInput[] }[]>(AccordionValue || []);
  const [titleValue, setTitleValue] = useState('');
  const [accordionValues, setAccordionValues] = useState<{ [key: string]: AccordionInput[] }>({});

  useEffect(() => {
    if (AccordionValue) {
      setAccordionSectionComponents(AccordionValue);
      const initialAccordionValues: { [key: string]: AccordionInput[] } = {};
      AccordionValue.forEach((section: { acc_title: string; content: AccordionInput[] }) => {
        initialAccordionValues[section.acc_title] = section.content;
      });
      setAccordionValues(initialAccordionValues);
    }
  }, [AccordionValue]);

  const handleAddAccordion = () => {
    const newAccordionComponent = {
      acc_title: titleValue,
      content: [{ question: 'Your Question', answer: 'Your Answer' }],
    };

    const updatedComponents = [...accordionSectionComponents, newAccordionComponent];
    setAccordionSectionComponents(updatedComponents);
    AccordionValueChange(updatedComponents);

    const updatedAccordionValues = {
      ...accordionValues,
      [titleValue]: [{ question: 'Your Question', answer: 'Your Answer' }],
    };

    setAccordionValues(updatedAccordionValues);
    setTitleValue('');
  };

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const updatedComponents = [...accordionSectionComponents];
    updatedComponents[index].acc_title = event.target.value;
    setAccordionSectionComponents(updatedComponents);
    AccordionValueChange(updatedComponents);

    const updatedAccordionValues = { ...accordionValues };
    delete updatedAccordionValues[accordionSectionComponents[index].acc_title];
    updatedAccordionValues[event.target.value] = updatedComponents[index].content;
    setAccordionValues(updatedAccordionValues);
  };

  const AddAccordionComponent = (content: AccordionInput[], index: number) => {
    const updatedComponents = [...accordionSectionComponents];
    updatedComponents[index] = { ...updatedComponents[index], content };
    setAccordionSectionComponents(updatedComponents);
    AccordionValueChange(updatedComponents);

    const updatedAccordionValues = {
      ...accordionValues,
      [updatedComponents[index].acc_title]: content,
    };
    setAccordionValues(updatedAccordionValues);
  };

  return (
    <div>
      {accordionSectionComponents.map((input, index) => (
        <div key={index}>
          <div className='mb-3'>
            <TextField
              className='bg-white w-100 mb-3'
              label="Accordion Title"
              name="acc_title"
              variant="outlined"
              value={input.acc_title}
              onChange={(event) => handleTitleChange(event, index)}
            />
          </div>
          <DynamicAccordion
            acc_title={input.acc_title}
            accordionValues={accordionValues[input.acc_title] || []}
            AccordionValueChange={(content: AccordionInput[]) => AddAccordionComponent(content, index)}
          />
        </div>
      ))}
      <Button onClick={handleAddAccordion}>Add Accordion Section</Button>
    </div>
  );
};

export default AccordionComponent;
