import { useEffect, useState } from 'react';
import BannerView from './Parts/BannerView';
import { Reorder } from 'framer-motion';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'; // Import delete icon
import AddIcon from '@mui/icons-material/Add'; // Import add icon
import TextWithImage from './Parts/TextWithImage';
import CenterBlock from './Parts/CenterBlock';
import Button from 'react-bootstrap/esm/Button';
import { ButtonInput } from './Parts/Components/types';
import ContentView from './Parts/ContentView';
import Blogs from './Parts/Blogs';
import ContactForm from './Parts/ContactForm';
import AccordionSection from './AccordionSection'
import Services from './Parts/Services';
interface PageReorderProps {
  pageValue: any;
  sendSetPagedata: (body: any) => void;
}

function PageReorder(props: PageReorderProps) {
  const { sendSetPagedata, pageValue } = props;
  const [pageData, setPageData] = useState<any>(pageValue);

  useEffect(() => {
    if (!pageValue || !Array.isArray(pageValue.body)) {
      setPageData({ body: [] });
    } else {
      setPageData(pageValue);
    }
  }, [pageValue]);

    // Initialize pageData with an empty array for body
    const handleReorder = (newOrder: any) => {
        const newData = { ...pageData, body: [...newOrder] };
        setPageData(newData);
        sendSetPagedata(newData);
    };
    const handleContentChange = (newContent: any, index: number) => {
        const updatedBody = [...pageData.body]; // Create a copy of the body array
        const updatedItem = { ...updatedBody[index], content: newContent }; // Create a copy of the item to update
        updatedBody[index] = updatedItem; // Update the specific item in the copied array
        setPageData({ ...pageData, body: updatedBody }); // Update the state with the new array
        sendSetPagedata({ ...pageData, body: updatedBody });
      };

    const handleHeadingChange = (newHeading: string, index: number) => {
        const updatedBody = [...pageData.body];
        updatedBody[index].heading = newHeading;
        setPageData({ ...pageData, body: updatedBody });
        sendSetPagedata({ ...pageData, body: updatedBody });
    };

    const handleSubheadingChange = (newSubheading: string, index: number) => {
        const updatedBody = [...pageData.body];
        updatedBody[index].subheading = newSubheading;
        setPageData({ ...pageData, body: updatedBody });
        sendSetPagedata({ ...pageData, body: updatedBody });

    };

    const handleSmallheadingChange = (newSmallHeading: string, index: number) => {
        const updatedBody = [...pageData.body];
        updatedBody[index].smallheading = newSmallHeading; // Correct property name
        setPageData({ ...pageData, body: updatedBody });
        sendSetPagedata({ ...pageData, body: updatedBody });

    };

    const handleRemoveItem = (index: number) => {
        const updatedBody = [...pageData.body];
        updatedBody.splice(index, 1);
        setPageData({ ...pageData, body: updatedBody });
        sendSetPagedata({ ...pageData, body: updatedBody });

    };
    const handleColumnsChange = (columns: string, index: number) => {
        // Create a shallow copy of the body array
        const updatedBody = [...pageData.body];
        // Create a shallow copy of the object at the specified index
        const updatedItem = { ...updatedBody[index] };
        // Update the property of the copied object
        updatedItem.setcolumns = columns;
        // Replace the object at the specified index with the updated object
        updatedBody[index] = updatedItem;
        // Update the state with the modified body array
        setPageData({ ...pageData, body: updatedBody });
        sendSetPagedata({ ...pageData, body: updatedBody });
      }

      const handleAddBlock = (layout: string) => {
        let newBlock;
      
        // Define default new block based on layout
        if (layout === "blogs") {
          newBlock = {
            layout: layout,
            heading: 'New ',
            subheading: 'New Subheading',
            columns: '',
            buttonValues: [],
          };
        } else if (layout === "content-view") {
          newBlock = {
            layout: layout,
            content: '',
            buttonValues: [],
          };
        } else {
          newBlock = {
            layout: layout,
            heading: 'New Heading',
            subheading: 'New Subheading',
            buttonValues: [],
          };
        }
      
        // Ensure pageData is initialized with a default value if it's undefined or null
        const initialPageData = pageData || { body: [] };
      
        // Ensure pageData.body is an array before attempting to update it
        const updatedBody = Array.isArray(initialPageData.body) ? [...initialPageData.body, newBlock] : [newBlock];
        setPageData({ ...initialPageData, body: updatedBody });
        sendSetPagedata({ ...initialPageData, body: updatedBody });
      };
      
  function buttonValueChange(index: number, values: ButtonInput[]): void {
  // Update the body of the page data
  const updatedBody = [...pageData.body];
  updatedBody[index].buttonValues = values; // Update button values at the specified index
  setPageData({ ...pageData, body: updatedBody });
  sendSetPagedata({ ...pageData, body: updatedBody });
  };
         
  const handlePathChange = async (imgPath: string, index: number) => {
  if (!pageData || !pageData.body) return;
            
  // Update the image path in the page data
  const updatedBody = [...pageData.body];
  updatedBody[index] = { ...updatedBody[index], img: imgPath };
  setPageData({ ...pageData, body: updatedBody });
  sendSetPagedata({ ...pageData, body: updatedBody });
  };

  function accordionSectionValueChange(AccordionValue: any, index: number) {
    const updatedBody = [...pageData.body];
    updatedBody[index].AccordionValue = AccordionValue; // Correct property name
    setPageData({ ...pageData, body: updatedBody });
    sendSetPagedata({ ...pageData, body: updatedBody });
  }
    return (
        <>
        <div>
        <Reorder.Group values={pageData?.body || []} onReorder={handleReorder}>
              {/* Mapping code */}
              {pageData &&
                pageData.body &&
                pageData.body.map((item: any, index: number) => (
                  <Reorder.Item key={`${index}`} value={item}>
                    {/* Render item content */}
                    {item.layout === 'banner' && (
                    <div className='mb-4' style={{"position": "relative"}}>
                      <BannerView
                          title={item.heading}
                          subheading={item.subheading}
                          onHeadingChange={(newHeading: string) => handleHeadingChange(newHeading, index)}
                          onSubheadingChange={(newSubheading: string) => handleSubheadingChange(newSubheading, index)}
                          buttonValueChange={(values) => buttonValueChange(index, values)}
                          setImagePath={(ImgPath: string) => handlePathChange(ImgPath, index)}
                          passBtnValue={item.buttonValues}
                          passImageValue={item.img}
                        />
                      {/* Other layout components */}
                      <IconButton className='hover-btn' onClick={() => handleRemoveItem(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                    )}
                    {item.layout === "center-block" && (
                    <div className='mb-4' style={{"position": "relative"}}>
                      <CenterBlock 
                      title={item.heading}
                      subheading={item.subheading}
                      onHeadingChange={(newHeading: string) => handleHeadingChange(newHeading, index)}
                      onSubheadingChange={(newSubheading: string) => handleSubheadingChange(newSubheading, index)}
                      buttonValueChange={(values) => buttonValueChange(index, values)}
                      setImagePath={(ImgPath: string) => handlePathChange(ImgPath, index)}
                      passBtnValue={item.buttonValues}
                      passImageValue={item.img}
                      />
                       {/* Other layout components */}
                       <IconButton className='hover-btn' onClick={() => handleRemoveItem(index)}> 
                         <DeleteIcon />
                       </IconButton>
                     </div>
                    )}
                    
                    {item.layout === "image-with-text-block" && (
                      <div className='mb-4' style={{"position": "relative"}}>
                       <TextWithImage 
                        smallheading={item.smallheading} 
                        title={item.heading} 
                        subheading={item.subheading}
                        onHeadingChange={(newHeading: string) => handleHeadingChange(newHeading, index)}
                        onSubheadingChange={(newSubheading: string) => handleSubheadingChange(newSubheading, index)}
                        onSmallHeadingChange={(newSmallHeading: string) => handleSmallheadingChange(newSmallHeading, index)}
                        buttonValueChange={(values) => buttonValueChange(index, values)}
                        setImagePath={(ImgPath: string) => handlePathChange(ImgPath, index)}
                        passBtnValue={item.buttonValues}
                        passImageValue={item.img}

                        />
                       {/* Other layout components */}
                       <IconButton className='hover-btn' onClick={() => handleRemoveItem(index)}>
                         <DeleteIcon />
                       </IconButton>
                     </div>
                    )}

                    {item.layout === "blogs" && (
                      <div className='mb-4' style={{"position": "relative"}}>
                       <Blogs pageValue={item}
                        onHeadingChange={function (newTitle: string): void {
                          handleHeadingChange(newTitle, index);
                        } }
                        onSubHeadingChange={function (newSubTitle: string): void {
                          handleSubheadingChange(newSubTitle, index);
                        } } 
                        onsetColumns={function (columns: any): void {
                          handleColumnsChange(columns, index);
                        } }                                             
                        />
                       {/* Other layout components */}
                       <IconButton className='hover-btn' onClick={() => handleRemoveItem(index)}>
                         <DeleteIcon />
                       </IconButton>
                     </div>
                    )}

                    {item.layout === "content-view" && (
                      <div className='mb-4' style={{"position": "relative"}}>
                       <ContentView pageValue={item} 
                       onContentChange={function (newContent: string): void {
                        handleContentChange(newContent, index)
                        } }
                        buttonValueChange={(values) => buttonValueChange(index, values)}
                        passBtnValue={item.buttonValues}   
                        />
                       {/* Other layout components */}
                       <IconButton className='hover-btn' onClick={() => handleRemoveItem(index)}>
                         <DeleteIcon />
                       </IconButton>
                     </div>
                    )}

                    {item.layout === "contact-form" && (
                      <div className='mb-4' style={{"position": "relative"}}>
                      <ContactForm pageValue={item}
                       onHeadingChange={function (newTitle: string): void {
                         handleHeadingChange(newTitle, index);
                       } }
                       onSubHeadingChange={function (newSubTitle: string): void {
                         handleSubheadingChange(newSubTitle, index);
                       } } 
                       onsetColumns={function (columns: any): void {
                         handleColumnsChange(columns, index);
                       } }                                             
                       />
                      {/* Other layout components */}
                      <IconButton className='hover-btn' onClick={() => handleRemoveItem(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                    )}

                    {item.layout === "accordion" && (
                      <div className='mb-4' style={{"position": "relative"}}>

                        <AccordionSection pageValue={item}
                        onHeadingChange={function (newTitle: string): void {
                          handleHeadingChange(newTitle, index);
                        } }
                        onSubHeadingChange={function (newSubTitle: string): void {
                          handleSubheadingChange(newSubTitle, index);
                        } }  
                        AccordionSectionValueChange={function (AccordionValue: any): void {
                          accordionSectionValueChange(AccordionValue, index);
                        } }  />
                      {/* Other layout components */}
                      <IconButton className='hover-btn' onClick={() => handleRemoveItem(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                    )}

                    {item.layout === "services" && (
                      <div className='mb-4' style={{"position": "relative"}}>
                       <Services pageValue={item}
                        onHeadingChange={function (newTitle: string): void {
                          handleHeadingChange(newTitle, index);
                        } }
                        onSubHeadingChange={function (newSubTitle: string): void {
                          handleSubheadingChange(newSubTitle, index);
                        } }          
                        buttonValueChange={(values) => buttonValueChange(index, values)}
                        passBtnValue={item.buttonValues}                              
                        />
                       {/* Other layout components */}
                       <IconButton className='hover-btn' onClick={() => handleRemoveItem(index)}>
                         <DeleteIcon />
                       </IconButton>
                     </div>
                    )}  
                  </Reorder.Item>
                ))}
            </Reorder.Group>
            <Button variant="primary" className='me-2 button-small mb-2' onClick={() => handleAddBlock('banner')}>
              <AddIcon /> Banner
            </Button>
            <Button variant="primary"  className='me-2 button-small mb-2' onClick={() => handleAddBlock('center-block')}>
              <AddIcon /> Center Block
            </Button>
            <Button variant="primary"  className='me-2 button-small mb-2' onClick={() => handleAddBlock('image-with-text-block')}>
              <AddIcon /> Text & Image
            </Button>
            <Button variant="primary"  className='me-2 button-small mb-2' onClick={() => handleAddBlock('blogs')}>
              <AddIcon /> Blogs
            </Button>
            <Button variant="primary"  className='me-2 button-small mb-2' onClick={() => handleAddBlock('content-view')}>
              <AddIcon /> HTML
            </Button>
            <Button variant="primary"  className='me-2 button-small mb-2' onClick={() => handleAddBlock('contact-form')}>
              <AddIcon /> Contact Form
            </Button>
            <Button variant="primary"  className='me-2 button-small mb-2' onClick={() => handleAddBlock('accordion')}>
              <AddIcon /> Accordion
            </Button>
            <Button variant="primary"  className='me-2 button-small mb-2' onClick={() => handleAddBlock('services')}>
              <AddIcon /> Services
            </Button>

            </div>
        </>
    );
}

export default PageReorder;