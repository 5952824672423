import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { mainListItems } from '../pages/Dashbaord/listItems';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import Navbar from '../pages/Dashbaord/navbar/navbar'

const drawerWidth: number = 300;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiToolbar-root' : {
      background: '#111827',
    },
    '& .MuiDrawer-paper': {
      position: 'relative',
      background: '#111827',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
      
      '& .css-mv42r1-MuiPaper-root' : {
        padding: '20px 15px',
        height : '360px',
      },
      '& .MuiListItemIcon-root' : {
        paddingLeft: '15px',
      },
      '& .css-cveggr-MuiListItemIcon-root' : {
        color: 'rgb(255 255 255 / 54%)',
      },
      '& .MuiButtonBase-root' : {
        color: 'rgb(255 255 255 / 54%)',
        padding: '10px',
        margin: '10px',
      },
      '& .MuiSvgIcon-fontSizeMedium' :  {

      },
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  typography: {
    fontFamily: [
      'Inter',
      'sans-serif',
    ].join(','),
},});


export default function ThemeContainer(props : any) {
  const navbar = {
    background: '#111827',
    borderLeft : 'solid 1px #414c61',
  };
    return (
    <ThemeProvider theme={defaultTheme} >
      <Box sx={{ display: 'flex', background : '#f5f7fe' }}>
        <CssBaseline />
        <AppBar  position="fixed">
        <Navbar />
        </AppBar>
  
        <Drawer
            variant="permanent"
            sx={{
              width: { sm: 240, md: 320 }, // Set the width for different breakpoints
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: { sm: 240, md: 320 }, // Set the width for different breakpoints
                boxSizing: 'border-box',
              },
            }}
          >
            <Toolbar
              sx={{
                display: { sm: 'none', md: 'flex' }, // Hide toolbar on small screens
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
              }}
            >
            </Toolbar>
            <Divider />
              {mainListItems}
          </Drawer>
          
          <Box component="main" className='mt-5' sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }} >
          <br />
                <Paper sx={{ p: 3, pb: 5, display: 'flex', flexDirection: 'column', boxShadow : 'none', 
                    borderRadius: '15px',
                  }}>
                    
                    {props.children}
                </Paper>

          </Box>
        </Box>
    </ThemeProvider>
  );
}

