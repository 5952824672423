import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BaseUrl from '../variable';

interface Post {
  id: number;
  title: string;
  body: string;
  author: string;
  createdAt: string;
  featured_img: string;
}

const SinglePostPage: React.FC = () => {
  
  const { id } = useParams<{ id: string }>();
  const [post, setPost] = useState<Post | null>(null);
  const [setFeaturedPath, setPath] = useState<Post | any>(null);
  const image_uri = `${BaseUrl}${setFeaturedPath}`;
  useEffect(() => {
    axios.get<Post>(`${BaseUrl}/api/post/single/${id}`)
      .then(response => {
        console.log(response.data)
        setPost(response.data);
        setPath(response.data.featured_img);
      })
      .catch(error => {
        console.error('Error fetching post:', error);
      });
  }, [id]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div>
     <div className='main-container'>
      <div className='banner-area' style={{background:`url(${image_uri})`,height:"300px", backgroundRepeat: "no-repeat", backgroundPosition:"center",backgroundSize: "cover"}}>
      </div>
      <Container>
        <Row>
          <Col lg={2}></Col>
          <Col lg={8}>
            <div className=' shadow p-5 mb-5' style={{marginTop: "-80px",background: "white"}}>
            <h1>{post.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: post.body }}></div>
            </div>
          </Col>
        </Row>
      </Container>
     </div>
    </div>
  );
};

export default SinglePostPage;
