import React, { Component } from 'react';
import Blogs from './Blogs'
import ThemeContainer from '../../common/ThemeContainer'

class index extends Component {
  render() {
    return (
      <div>
        <ThemeContainer>
        <h4 className='mt-4 mb-4 fw-bold text-start'>Add a new Post</h4>
        <Blogs />
        </ThemeContainer>
      </div>
    );
  }
}

export default index;